import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { collection, getDocs, query, where } from 'firebase/firestore';

import { webFormsApi } from '../../axios';
import { signatureApi } from '../../axios/signature';
import { useFeatureFlags } from '../../hooks';
import { useFirebaseStore } from '../../newStore/firebaseStore';
import {
  Finding,
  FormAnswerWithTaskAssign,
  Question,
  SignerInfo,
  UserFormSignatureInfo,
  UserSignatureState,
  WebFormsAnswer,
} from '../../types';
import { getQueryParams } from '../../utils/utils';

interface AnswerViewContextType {
  answerInfo: FormAnswerWithTaskAssign;
  answers: Record<string, WebFormsAnswer<unknown, unknown>>;
  loading: boolean;
  findingsList: Finding[];
  isPublicView: boolean;
  token?: string;
  questions?: Question[];
  signatureInfo: UserFormSignatureInfo;
  userSignatureState: UserSignatureState;
  taskFormId: string;
  formAnswerId: string;
  isEmbedded?: boolean;
  completedSignatures: SignerInfo[];
}

const AnswerViewContext = createContext<AnswerViewContextType | null>(null);

interface AnswerViewProviderProps {
  children: ReactNode;
  formAnswerId?: string | number;
  taskFormId?: string | number;
  isEmbedded?: boolean;
}

export const AnswerViewProvider = ({
  children,
  formAnswerId: propFormAnswerId,
  taskFormId: propTaskFormId,
  isEmbedded = false,
}: AnswerViewProviderProps) => {
  const { form_answer_id: urlFormAnswerId, token, task_form_id: urlTaskFormId } = getQueryParams();

  const formAnswerId = propFormAnswerId?.toString() || urlFormAnswerId;
  const taskFormId = propTaskFormId?.toString() || urlTaskFormId;

  const { firestore, user, firebaseConfigured } = useFirebaseStore();
  const { getPremiumFlag } = useFeatureFlags();
  const findingsActivated = !!getPremiumFlag('Findings');

  const [answerInfo, setAnswerInfo] = useState<AnswerViewContextType['answerInfo']>(null);
  const [answers, setAnswers] = useState<AnswerViewContextType['answers']>({});
  const [loading, setLoading] = useState(true);
  const [findingsList, setFindingsList] = useState<AnswerViewContextType['findingsList']>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [signatureInfo, setSignatureInfo] = useState<UserFormSignatureInfo>(null);
  const [userSignatureState, setUserSignatureState] = useState<UserSignatureState>(null);
  const [completedSignatures, setCompletedSignatures] = useState<SignerInfo[]>([]);
  const isPublicView = !!token;

  const fetchFormAnswerAndTaskInfo = async () => {
    try {
      const response = await webFormsApi.getFormAnswerWithTaskData(
        formAnswerId,
        isPublicView ? token : undefined,
      );
      setAnswerInfo(response);
    } catch (error) {
      console.error('Error fetching form answer and task info:', error);
    }
  };

  const fetchTaskFormQuestions = async () => {
    try {
      const taskForm = await webFormsApi.getForm(taskFormId, null);
      setQuestions(taskForm.questions);
    } catch (error) {
      console.error('Error fetching task form questions:', error);
    }
  };

  const fetchFormAnswerAnswers = async () => {
    try {
      const response = isPublicView
        ? await webFormsApi.getPublicFormAnswer(formAnswerId, token)
        : await webFormsApi.getFormAnswer(formAnswerId);
      setAnswers(response);
    } catch (error) {
      console.error('Error fetching form answers:', error);
    }
  };

  const fetchSignatureInfo = async () => {
    if (isPublicView) return;

    try {
      const infoResponse = await signatureApi.getUserFormSignInfo(formAnswerId);
      setSignatureInfo(infoResponse);
      const stateResponse = await signatureApi.getUserSignatureState();
      setUserSignatureState(stateResponse);
      const completedSignaturesResponse = await signatureApi.getFormAnswerCompletedSignatures(
        formAnswerId,
      );
      setCompletedSignatures(completedSignaturesResponse);
    } catch (error) {
      console.error('Error fetching signature info:', error);
    }
  };

  const getFindings = async () => {
    if (!findingsActivated) return;

    try {
      const findingsQuery = query(
        collection(firestore, `datascope/${user.uid}/findings`),
        where('form_answer.id', '==', parseInt(formAnswerId)),
      );

      const findingsSnapshot = await getDocs(findingsQuery);
      const findingsList = findingsSnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as Finding;
      });
      setFindingsList(findingsList);
    } catch (error) {
      console.error('Error fetching findings:', error);
    }
  };

  useEffect(() => {
    const fetchAnswerView = async () => {
      setLoading(true);
      await Promise.all([
        fetchFormAnswerAndTaskInfo(),
        fetchFormAnswerAnswers(),
        fetchSignatureInfo(),
      ]);
      setLoading(false);
    };
    fetchAnswerView();
  }, [formAnswerId]);

  useEffect(() => {
    if (taskFormId) {
      fetchTaskFormQuestions();
    }
  }, [taskFormId]);

  useEffect(() => {
    if (!isPublicView && firebaseConfigured) getFindings();
  }, [firebaseConfigured]);

  const answerViewValue = {
    answerInfo,
    answers,
    loading,
    findingsList,
    isPublicView,
    token,
    questions,
    signatureInfo,
    userSignatureState,
    taskFormId,
    formAnswerId,
    isEmbedded,
    completedSignatures,
  };
  return (
    <AnswerViewContext.Provider value={answerViewValue}>{children}</AnswerViewContext.Provider>
  );
};

export const useAnswerViewContext = () => useContext(AnswerViewContext);
