import {
  ExternalUserFormSignatureInfo,
  FormAnswerSignatureState,
  PdfLink,
  SignatureBody,
  SignatureIndex,
  SignatureIndexParams,
  SignatureRequestResponse,
  SignatureRequestTimelineResponse,
  SignatureResponse,
  SignerInfo,
  UpdateExternalUserSignatureInfoBody,
  UserFormSignatureInfo,
  UserSignatureState,
} from '../types/signature';
import { client } from './index';

async function getSignature(type: string) {
  const response = await client.get<SignatureResponse>(
    `signatures/${type === 'user' ? 'mobile_user_config' : 'account_config'}`,
  );
  const { data } = response;
  return data;
}

async function updateAccountSignature(body: SignatureBody, userId: string) {
  const response = await client.post(`users/update_react/${userId}`, body);
  const { data } = response;
  return data;
}

async function getUserSignatureState() {
  const response = await client.get<UserSignatureState>('signatures/mobile_user/state');
  const { data } = response;
  return data;
}

async function getFormAnswerSignatureState(formAnswerId: string) {
  const response = await client.get<FormAnswerSignatureState>(
    `signatures/form_answer/${formAnswerId}`,
  );
  const { data } = response;
  return data;
}

async function getUserFormSignInfo(formAnswerId: string) {
  const response = await client.get<UserFormSignatureInfo>(
    `signatures/mobile_user/request/${formAnswerId}`,
  );
  const { data } = response;
  return data;
}

async function getExternalUserFormSignInfo(token: string) {
  const response = await client.get<ExternalUserFormSignatureInfo>(
    `signatures/get_external_user?token=${token}`,
  );
  const { data } = response;
  return data;
}

async function saveExternalUserSignatureInfo(
  token: string,
  body: UpdateExternalUserSignatureInfoBody,
) {
  const response = await client.post(`signatures/update_external_user?token=${token}`, body);
  const { data } = response;
  return data;
}

async function externalUserSignFormAnswer(token: string, formAnswerId: string) {
  const response = await client.put(
    `signatures/form_answer/${formAnswerId}/external_sign?token=${token}`,
  );
  const { data } = response;
  return data;
}

async function externalUserRequestNewLink(token: string) {
  const response = await client.post(
    `signatures/resend_individual_signature_request_email?token=${token}`,
  );
  const { data } = response;
  return data;
}

async function signFormAnswer(formAnswerId: string) {
  const response = await client.put(`signatures/form_answer/${formAnswerId}/sign`);
  const { data } = response;
  return data;
}

async function getSignatureIndex(params: SignatureIndexParams) {
  const response = await client.get<SignatureIndex>('signatures/signatures_index', {
    params,
  });
  const { data } = response;
  return data;
}

async function getSignatureRequests(
  search?: string,
  page?: number,
  status?: 'pending' | 'completed',
) {
  const params = new URLSearchParams();

  if (search) params.append('search', search);
  if (page) params.append('page', page.toString());
  if (status) params.append('status', status);

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const url = `signatures/requests_index${queryString}`;

  const response = await client.get<SignatureRequestResponse>(url);
  const { data } = response;
  return data;
}

async function shareSignedPdf(requestId: number, emails: string[]) {
  const response = await client.post(`signatures/${requestId}/share_email`, { emails });
  const { data } = response;
  return data;
}

async function deleteSignatureRequest(requestId: number) {
  const response = await client.delete(`signatures/requests/${requestId}`);
  const { data } = response;
  return data;
}

async function getSignedPdfUrl(requestId: number) {
  const response = await client.get<PdfLink>(`signatures/${requestId}/pdf_link`);
  const { data } = response;
  return data;
}

async function bulkSign(requestIds: number[]) {
  const response = await client.post('signatures/requests/bulk_sign', { requests_ids: requestIds });
  const { data } = response;
  return data;
}

async function bulkSignReject(
  rejects: Record<string, number>,
  formAnswerId?: string,
  token?: string,
): Promise<void> {
  await client.patch(
    'signatures/requests/bulk_reject',
    { rejects, form_answer_id: formAnswerId, token },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
}

async function externalSignReject(formAnswerId: number, token: string, requestId?: number) {
  const response = await client.patch(`signatures/requests/external_reject?token=${token}`, {
    form_answer_id: formAnswerId,
    rejects: { [requestId]: 0 },
  });
  const { data } = response;
  return data;
}

async function sendSlackNotification() {
  const response = await client.post('signatures/notify_sales');
  const { data } = response;
  return data;
}

async function regenerateSignatureRequest(formAnswerId: string) {
  const response = await client.post(`signatures/form_answer/${formAnswerId}/regenerate`);
  const { data } = response;
  return data;
}

async function getSignatureRequestTimeline(requestId: number) {
  const response = await client.get<SignatureRequestTimelineResponse>(
    `signatures/requests/${requestId}/timeline`,
  );
  const { data } = response;
  return data;
}

async function getFormAnswerCompletedSignatures(formAnswerId: string) {
  const response = await client.get<SignerInfo[]>(
    `signatures/form_answer/${formAnswerId}/completed`,
  );
  const { data } = response;
  return data;
}

export const signatureApi = {
  getSignature,
  updateAccountSignature,
  getUserSignatureState,
  getFormAnswerSignatureState,
  getUserFormSignInfo,
  getExternalUserFormSignInfo,
  saveExternalUserSignatureInfo,
  externalUserSignFormAnswer,
  externalUserRequestNewLink,
  signFormAnswer,
  getSignatureRequests,
  getSignatureIndex,
  shareSignedPdf,
  bulkSign,
  bulkSignReject,
  getSignedPdfUrl,
  deleteSignatureRequest,
  sendSlackNotification,
  getSignatureRequestTimeline,
  externalSignReject,
  regenerateSignatureRequest,
  getFormAnswerCompletedSignatures,
};
