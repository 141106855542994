export const Portuguese = {
  lang: 'pt',
  wizard: {
    createAccount: 'Cria tua conta gratuita',
    noCreditCard: 'Sem cartão de crédito, cancele quando quiser',
    name: 'Nome',
    lastName: 'Sobrenome',
    email: 'Correio eletrônico',
    companyName: 'Companhia',
    role: 'Posição',
    dialCode: 'País',
    phoneNumber: 'Telefone',
    fullNumber: 'País e telefone',
    password: 'Senha',
    saveButton: 'Mandar',
    roles: [
      'CEO / Presidente',
      'Diretor de Operações',
      'Diretor de Segurança',
      'Finanças - Profissional',
      'Finanças - VP / Gerente',
      'IT - Profissional',
      'TI - VP / Gerente',
      'Marketing',
      'Operações - Profissional',
      'Operações - VP / Gerente',
      'Vendas - Profissional',
      'Vendas - VP / Gerente',
      'Serviços - Profissional',
      'Serviços - VP / Gerente',
      'Consultor',
      'Professor',
      'Estudante',
      'Outro',
    ],
    captchaError: 'Captcha não foi validado',
    inputError: 'Preencha o campo',
    emailExistsError: 'O email está em uso',
    phoneNumberError: 'Apenas números',
    passwordError: 'Deve ter pelo menos 6 caracteres',
    emailError: 'Digite um email válido',
    emailInfo: 'Com este e-mail, você pode entrar na plataforma de aplicativos e web',
    privacyMessage: 'Ao clicar, você aceita que concorda com o ',
    privacy: 'Política de privacidade',
    preparingForm: 'Estamos criando sua conta e preparando o formulário',
    preparingForm2: 'Isso pode demorar alguns segundos',
    preparingForm3: 'Estamos preparando o formulário',
    userHasAccount: 'Você já tem uma conta?',
    login: 'Acessar',
  },
  wizardFinish: {
    buildYourFirstForm: 'Crie seu primeiro formulário móvel',
    formTemplates: 'Modelos de formulários',
    createForm: 'Criar formulário',
    welcome: 'Bem-vindo!',
    welcomeDescription1: 'De agora em diante, você poderá ',
    welcomeDescription2:
      'acessar a plataforma web e móvel utilizando as credenciais recém inseridas.',
    welcomeDescription3:
      'Lembre-se que para começar a coletar dados, você só precisa baixar o aplicativo da Google Play ou da App Store em seu telefone ou tablet.',
  },
  task_assigner: {
    assigned: 'Atribuído',
    completed: 'Completado',
    pending: 'Em andamento',
    incomplete: 'Incompleto',
    states: {
      completed: 'Completado',
      pending: 'Em andamento',
      incomplete: 'Incompleto',
      rejected: 'Rejeitado',
      programmed: 'Programado',
      sent: 'Enviado',
    },
    cards: {
      totalTasks: 'Total tarefas',
      completed: 'Completados',
      pending: 'Em andamento',
      incomplete: 'Incompletos',
      rejected: 'Rejeitados',
      programmed: 'Programados',
      sent: 'Enviados',
    },
    filters: 'Filtros',
    new_task: 'Nova Tarefa',
    import: 'Importar',
    edit_task: 'Editar Tarefa',
    tasks: 'Tarefas',
    massive_search: 'Pesquise por usuário, formulário ou endereço ...',
    user: 'Usuário',
    form: 'Formulário',
    place: 'Endereço',
    date: 'Data',
    date_and_time: 'Data e hora',
    gap: 'Intervalo',
    state: 'Estado',
    save: 'Salvar',
    users: 'Usuários',
    description: 'Descrição',
    hour: 'Hora',
    alert: 'alerta',
    delete: 'Excluir',
    cancel: 'cancelar',
    accept: 'aceitar',
    notify: 'Notificar',
    notify_user: 'Quero notificar ',
    sure: 'Você tem certeza?',
    delete_task: 'Você irá excluir esta tarefa',
    delete_tasks: 'Você irá excluir estas tarefas: ',
    reset: 'Restabelecer',
    old_version: 'Versão Antiga',
    task_created: 'Tarefa criada',
    our_problem: 'Temos um problema',
    task_update: 'Tarefa atualizada com sucesso',
    export: 'Exportar',
    date_necessary: 'uma data é necessária',
    required: 'Requerido',
    blanck: 'Existem campos vazios',
    notification_sent: 'Notificação enviada com sucesso',
    notifications_sent: 'Notificações enviadas com sucesso',
    tasks_delete:
      'Tarefas excluídas com sucesso. Aguarde alguns minutos para ver as alterações refletidas.',
    task_delete: 'Tarefa excluída com sucesso',
    new_place: 'Novo endereço',
    start: 'Começa',
    end: 'Final',
    history: 'Registro',
    actions: '',
    dobleClick: 'Clique duplo na tarefa = abrir detalhes',
    shiftMouse: 'shift + roda do mouse = mover linha do tempo esquerda/certa',
    altMouse: 'alt + roda do mouse = zoom in/out',
    timeline: 'Linha do tempo',
    taskAssignID: 'ID tarefa atribuída',
    selected_range: 'Intervalo selecionado',
    notifications: 'Adicionar notificações',
    add_notification: 'Adicionar uma notificação',
    seconds: 'segundos',
    minutes: 'minutos',
    hours: 'horas',
    days: 'dias',
    weeks: 'semanas',
    months: 'meses',
    years: 'anos',
    notification: 'Notificação',
    email: 'Email',
    max_one_date: 'Máximo uma data no modo de edição',
    max_one_user: 'Máximo um usuário no modo de edição',
    no_location_warning: 'O endereço não foi especificado, ou pode ter sido removido',
    showing_results_for: 'Mostrando resultados para',
    task: 'Tarefa',
    no_tasks_available: 'Você não tem Tarefas disponíveis neste moment',
    gap_hours: 'Duração das tarefas em horas',
    allow_late_submission: 'Permitir responder à tarefa após o prazo ter expirado',
    add_tasks: 'Adicionar tarefas',
    add_tasks_caption:
      'Você pode adicionar tarefas rapidamente separando-as com vírgulas "," ou pressionando Enter',
    configuration: 'Configuração',
    voluntary_task:
      'Tornar esta tarefa voluntária (Todos os usuários podem aceitar/rejeitar a tarefa)',
    mandatory_task: 'Tornar esta tarefa obrigatória (Todos os usuários devem responder à tarefa)',
    notification_type: 'Tipo de notificação',
    notification_time: 'Tempo',
    notification_period: 'Período de tempo',
    app_notification: 'Notificação no aplicativo',
    confirmation_title_prefix: ' Você tem certeza de que deseja criar ',
    confirmation_title_suffix: ' tarefas?',
    confirmation_title_suffix_one: 'tarefas por',
    confirmation_title_suffix_two: 'dias?',
    edit_confirmation_title: 'Você tem certeza de que deseja editar esta tarefa?',
    success_message:
      'Tarefas criadas com sucesso! Por favor, aguarde alguns segundos para que sejam refletidas. Lembre-se de atualizar a página para visualizá-las.',
    update_success_message: 'Tarefa atualizada com sucesso!',
    forms_info: 'Se você selecionar vários formulários, criará várias tarefas com os selecionados.',
    edit: 'Editar',
    show_answer: 'Mostrar resposta',
    notify_title: 'Você tem certeza de que deseja notificar o seguinte usuário?',
    bulk_notify_title: 'Você tem certeza de que deseja notificar os seguintes usuários?',
    delete_title: 'Você tem certeza de que deseja excluir a tarefa?',
    delete_description: 'Será excluído e você não poderá recuperá-lo',
    bulk_delete_title_one: 'Você tem certeza de que deseja excluir',
    bulk_delete_title_two: 'tarefas?',
    bulk_delete_description:
      'Se você excluí-los, eles desaparecerão da lista de tarefas pendentes dos usuários e eles não poderão respondê-los',
    bulk_actions: 'Ações em massa',
    creation_date: 'Data criação',
    start_date: 'Data início',
    answers: 'Respostas',
    duration: 'Duração:',
    answered: 'responderam',
    out_of_time: 'Fora de tempo',
    late: 'atrasado',
    no_location: 'Sem endereço',
    task_does_not_exist: 'A tarefa não existe',
    location_copied: 'Localização copiada para a área de transferência',
    completed_tasks_cannot_be_deleted: 'Tarefas completadas não podem ser excluídas',
  },
  form_answer: {
    answer_again: 'Responder novamente',
    success_message: 'Recebemos sua respostas corretamente',
    submit_button: 'Enviar',
    edit_button: 'Editar',
    incomplete_edit_alert: 'Você deve responder a todas as perguntas obrigatórias',
    incomplete_submit_alert: 'Você ainda não enviou nenhuma resposta',
    error_submit_alert: 'Ocorreu um erro ao enviar sua resposta',
    error_edit_alert: 'Ocorreu um erro ao editar a sua resposta',
    no_edit_alert: 'Você ainda não editou a resposta original',
    no_answer_alert: 'Sua resposta está vazia',
    required_question: 'Esta pergunta é obrigatória',
    no_options: 'Sem opções',
    invalid_question_type_1: 'Pergunta tipo ',
    invalid_question_type_2:
      'Este tipo de pergunta não pode ser respondida/editada em nossa plataforma web. Baixe o aplicativo móvel DataScope.',
    some_invalid_question_types:
      'Aviso: este formulário inclui tipos de perguntas inválidos para a versão da Web do DataScope',
    download_mobile: 'Baixe nosso aplicativo móvel aqui',
    download_mobile_link: 'https://datascope.page.link/DQbR',
    answer_placeholder: 'Sua resposta',
    simple_text_placeholder: 'Sua resposta de texto',
    text_rut_placeholder: 'Seu RUT de resposta',
    text_container_placeholder: 'Seu ID de contêiner',
    invalidRutError: 'RUT inválido',
    invalidContainerError: 'ID de contêiner inválido',
    text_email_placeholder: 'Seu e-mail de resposta',
    select_your_date: 'Seleciona uma data',
    select_your_datetime: 'Seleciona data e hora',
    select_your_time: 'Seleciona um horário',
    datetime_errors: {
      invalidDate: 'Data inválida',
      minDate: 'A data é anterior à data mínima permitida',
      maxDate: 'A data é posterior à data máxima permitida',
    },
    add_repeatable_questions: 'Adicionar outro grupo de perguntas',
    signature_type: 'Assinatura',
    photo_type: 'Foto',
    barcode_type: 'Código de barras',
    filter_elements: 'Procurar elementos...',
    upload_image: 'Enviar Imagem',
    no_image_loaded: 'Nenhuma imagem foi enviada ainda',
    image_max_size_exceeded: 'Um ou mais de seus arquivos excedem o límite de 10MB',
    image_max_amount_exceeded_part_one: 'Você pode subir até',
    image_max_amount_exceeded_part_two: 'imágenes por comentario',
    image_upload_error: 'Ocorreu um erro ao enviar os arquivos, tente novamente',
    no_signature_loaded: 'Nenhuma firma foi enviada ainda',
    clear: 'Limpar',
    save_canvas: 'Guardar',
    modify_signature: 'Modificar',
    next: 'Próximo',
    previous: 'Atrás',
    upload_file: 'Subir arquivo',
    unsupported_file_type: 'Este tipo de arquivo não é permitido para esta pergunta.',
    exceeded_max_number_files:
      'Você excedeu o número máximo de arquivos permitidos para esta questão',
    download_app_suggestion:
      'Procura uma forma melhor e mais rápida de responder aos seus formulários?',
    confirm_section_change:
      'Esta seção tem uma pergunta obrigatória que você ainda não respondeu corretamente. Deseja continuar?',
    load_more_data: 'Carregar mais dados',
    no_data_left: 'Não há dados restantes para carregar',
    clear_all: 'Limpar tudo',
    no_header_warning:
      'O criador desta questão não especificou os títulos das listas relacionadas.',
    more_answers_alert: 'Role para baixo para ver mais',
    subform_limit: 'Limite do grupo de perguntas atingido',
    subform_min: 'Por favor, adicione o número mínimo de respostas',
    multi_option_placeholder: 'Seleciona uma opção',
    image: 'Imagem',
    download: 'Descarga',
    multiple_options: 'Selecione várias opções',
    group: 'Grupo',
    start: 'Começo',
    end: 'Final',
    invalid_date: 'Data inválida',
    invalid_time: 'Hora inválida',
    optionComments: {
      addNote: 'Adicionar uma nota',
      removeNote: 'Excluir nota',
      writeComment: 'Escrever um comentário...',
      requiredNote: 'Comentário obrigatório',
      addNoteMobile: 'Nota',
      addImage: 'Anexar imagem',
      addImageMobile: 'Imagem',
      removeNoteMobile: 'Excluir',
      requiredImage: 'Imagem obrigatória',
      deleteImageWarningTitle: 'Tem certeza de que deseja excluir esta imagem?',
      deleteImageWarningSubTitle: 'Uma vez excluída, você não poderá recuperá-la',
      cancel: 'Cancelar',
      delete: 'Excluir',
    },
    drag_drop_file: 'Arraste e solte seus arquivos aqui',
    or_browse_for_file: 'ou procure por eles em seu computador',
    missingRequiredAnswersAlert:
      'Ops! Parece que ainda faltam algumas perguntas para responder. Por favor, complete todas as perguntas obrigatórias para poder continuar.',
    totalPercentage: 'Porcentagem total',
    enterSampleTotal: 'Digite o total da amostra',
    forbidden_access:
      'Parece que você não tem permissão para editar este formulário. Solicite-a para poder fazer alterações',
    automated_signature_on_response:
      'Este formulário tem assinatura automática configurada. A solicitação será enviada aos usuários atribuídos ao finalizar o formulário.',
    electronic_signature: 'Assinatura eletrônica',
    sign_now: 'Assinar agora',
    sign_later: 'Assinar mais tarde',
    sign_modal_description: 'Este formulário requer sua assinatura, você deseja assinar agora?',
    signature_creation_failed: 'Houve um erro ao tentar assinar o formulário',
    saveSuccessAndRedirect: 'Resposta salva corretamente! Redirecionando para suas assinaturas...',
    saveAndSignSuccessAndRedirect:
      'Resposta salva e assinada corretamente! Redirecionando para suas assinaturas...',
  },
  percentage: 'Porcentagem',
  build_your_own_form: 'Construa seu próprio formulário com DataScope',
  back_to_taskForms: 'Voltar à lista de formulários',
  date_fns: 'pt',
  yes: 'Sim',
  no: 'Não',
  insert_value: 'Insira um valor',
  and: 'E',
  invalidEmail: 'Email inválido',
  ok: 'OK',
  clearAll: 'Remover tudo',
  group: 'Grupo',
  folder: 'Arquivo',
  users: 'Usuários',
  forms: 'Formulários',
  actions: 'Ações',
  select: 'Selecionar',
  selectAll: 'Selecionar Tudo',
  form_builder: {
    formName: 'Nome do formulario',
    saveForm: 'Salvar',
    title: 'Título',
    titleDescription: 'Título curto para identificar a questão. Aparece ao exportar dados.',
    instruction: 'Descrição',
    instructionDescription:
      'Texto mais longo para exibir no aplicativo. Não aparece ao exportar dados (opcional).',
    questionType: 'Alterar tipo de pergunta',
    copy: 'Duplicar pergunta',
    delete: 'Apagar pergunta',
    add: 'Adicionar nova pergunta',
    advanced: 'Opções avançadas',
    type: 'Tipo',
    date: 'Data',
    dateAndTime: 'Data e hora',
    time: 'Hora',
    alternativeColumns: 'Colunas alternativas',
    addOption: 'Adicionar opção',
    dataListSource: 'Fonte da lista de dados',
    changeDataList: 'Alterar lista de dados',
    listValues: 'Valores de lista',
    addListValue: 'Adicionar valor de lista',
    deleteAlternative: 'Excluir alternativa',
    required: 'Obrigatória',
    validateRutChile: 'RUT (Chile)',
    validateMail: 'Correio eletrônico',
    validateContainer: 'ID de contêiner',
    validate: 'Validar',
    codeType: 'Alterar tipo de código',
    disableGallery: 'Desativar galeria',
    maxImagesPerItem: 'Máximas imagens por item',
    moveUp: 'Mover para cima',
    moveDown: 'Mover para baixo',
    alternative: 'Alternativa',
    snackBarShared: 'convites enviados',
    snackBar: 'Seu formulário foi salvo com sucesso!',
    newListData: 'Nova Lista De Dados',
    section: 'Adicionar Seção',
    sectionName: 'Seção sem nome',
    duplicateSection: 'Replicar seção',
    deleteSection: 'Deletar seção',
    delSectionsQuestions: 'deletar seção e perguntas',
    areYouSure: 'Tem certeza?',
    cancel: 'Cancelar',
    sectionAlertOne: 'A seção será removida, mas as perguntas serão adicionadas à seção anterior.',
    sectionAlertTwo: 'A seção será excluída e também as perguntas dentro dela.',
    yesDelete: 'Sim, eliminar',
    questionAlert: 'Esta questão será removida permanentemente',
    copyName: 'Cópia',
    nameAlert: 'As perguntas devem ter nomes diferentes.',
    isRepeated: 'é repetido.',
    if: 'se',
    is: 'é',
    to: 'a',
    goTo: 'ir a',
    sectionNameTag: 'Nome da Seção',
    skipQuestions: 'Saltar perguntas',
    equal: 'igual',
    dif: 'diferente',
    empty: 'vazio',
    notEmpty: 'não vazio',
    skipOnlyMobile: 'Somente aplicável para formulários móveis',
    disableSkip: 'Este tipo de pergunta não está habilitada para a lógica de saltos',
    next: 'próxima questão',
    end: 'terminar formulário',
    payingAlert:
      'Esta funcionalidade não está disponível no plano gratuito, você deve ativar seu plano na configuração / faturamento.',
    showPercent: 'Mostrar porcentagem',
    showSamplingPercent: 'Mostrar porcentagem de amostragem',
    showPrice: 'Mostrar preço',
    showAvg: 'Mostrar média',
    showFactor: 'Mostrar multiplicação',
    camQuality: 'Qualidade da câmera',
    high: 'Alta',
    mid: 'Media',
    low: 'Baixa',
    recommended: 'recomendado',
    photoAlert: 'a sincronização pode demorar mais',
    sectionNameAlert: 'Não deve haver seções com o mesmo nome. Renomeie a seção: ',
    formatType: 'Tipos de formato',
    any: 'Qualquer',
    howToCreate: 'como criar formulários',
    good: 'Bom',
    regular: 'Regular',
    bad: 'Mau',
    newOption: 'Nueva opção',
    moveSection: 'Mover seção',
    oldShareForm: 'Compartilhe este formulário com um link público',
    shareForm: 'Link Formulário',
    shareEmail: 'Compartilhe através da email',
    shareWhatsapp: 'Compartilhe através da WhatsApp',
    shareWhatsappMessage: 'Envie seu formulário junto com uma mensagem via WhatsApp',
    shareWhatsappMessageApi: 'Olá, convido você a preencher o seguinte formulário DataScope: ',
    formMadePublic: 'O link do formulário foi ativado',
    undoFormPublic: 'O link do formulário foi desativado',
    shareForm2: 'Compartilhe este formulário',
    shareFormTip:
      'Qualquer pessoa, usuários registrados e não registrados, poderá preencher este formulário web se você compartilhar o link público com eles. Suas respostas serão registradas com o nome de um usuário genérico.',
    shareFormOn: 'Tornar este formulário público',
    shareFormOff: 'Remover o acesso público',
    shareViaEmailTip: 'Pode incluir mais de um e-mail pressionando o enter, vírgula ou um espaço',
    quantity: 'Número máximo de arquivos',
    new: 'Novo!',
    warning: 'Temporariamente disponível apenas para formulários da web',
    testYourForm1: 'Baixa nosso aplicativo oficial em ',
    testYourForm2: 'Mais tarde, você pode fazer login com essas mesmas credenciais.',
    testYourForm3: 'Teste seu formulário em nosso aplicativo!',
    testYourForm4:
      'Digitalize este código QR com a câmera do seu celular para visualizar o seu formulário!',
    testYourForm5:
      'Digitalize este código QR com a câmera do seu celular para baixar nosso aplicativo e visualizar seu formulário!',
    copyLink: 'Copiar link',
    copiedLink: 'Copiado!',
    visitForm: 'Visita',
    accessYourForm: 'Acesse seu formulário aqui',
    downloadApp: 'Baixe nosso aplicativo',
    ok: 'ok',
    and: 'e',
    greater: 'Maior que',
    greaterOrEqual: 'Maior ou igual que',
    less: 'Menor que',
    lessOrEqual: 'Menor ou igual que',
    between: 'Entre',
    notBetween: 'Não está entre',
    number: 'Número',
    noValidation: 'Sem validação',
    validNumberWarning: 'Insira um número válido',
    validSetWarning: 'Insira um conjunto válido',
    separator: 'Separador',
    separatorWarning: 'Insira um separador válido',
    relatedList: 'Lista relacionada',
    header: 'Cabeçalho',
    headerPlaceholder: 'País | Cidade | Rua',
    headerWarning: 'Você deve inserir um cabeçalho que contenha o separador.',
    headerDefault: 'Lista sem cabeçalho',
    deprecatedWarning: 'Este tipo de pergunta não é mais suportado',
    combineAlert1: 'As perguntas só podem ser arrastadas para grupos de perguntas repetíveis.',
    combineAlert2:
      'Um grupo de perguntas repetíveis não pode conter outro grupo de perguntas repetíveis.',
    combineAlertTitle: 'Erro',
    emptySubform: 'Esvaziar',
    emptySubformAlert:
      'Não é possível deixar um grupo de perguntas repetíveis vazio; uma pergunta foi criada automaticamente.',
    emptySubformAlertTitle: 'Aviso',
    emptySubformTooltip: 'Remover todas as perguntas do grupo de perguntas repetíveis.',
    removeSubformQuestionTooltip: 'Remover pergunta do grupo de perguntas repetíveis.',
    subformRemoveConfirm:
      'Esta pergunta será removida deste grupo de perguntas repetíveis e adicionada ao formulário principal.',
    subformEmptyConfirm:
      'Todas as perguntas serão removidas do grupo de perguntas repetíveis e adicionadas ao formulário principal.',
    confirm: 'Confirmar',
    subformLimit: 'Limite de repetições',
    subformWarning: 'Para mais de 5 repetições, atualize seu plano em ajustes > faturamento',
    answer: 'Responda',
    includeComments: 'Comentário',
    commentsFormLabel: 'Selecione as respostas nas quais deseja incluir um comentário obrigatório',
    textCommentBtnTooltip: 'Texto Obrigatório',
    imageCommentBtnTooltip: 'Imagem Obrigatória',
    imageCommentBtnTooltipDisabled: 'Você não pode adicionar mais de 500 imagens em total',
    share: 'Compartilhar',
    mobileTab: 'Celular',
    webTab: 'Computadora',
    max: 'Máximo',
    min: 'Mínimo',
    asCount: 'Contador',
    dataListSearchModalTitle: 'Minhas listas',
    editLists: 'Editar listas',
    use: 'Usar',
    usedInThisForm: 'Usadas neste formulário',
    otherLists: 'Outras listas',
    multi_photo: 'Múltiplas fotos',
    mobileOnlyAlert:
      'Disponível apenas no aplicativo. Se você tornar esta pergunta obrigatória, poderá responder ao formulário pela web, mas a pergunta não será exibida.',
    availableAppWeb: 'Disponível na Web e no app',
    imageDisabledByLimit:
      'A opção de imagem foi desativada. Não é possível subir mais de 500 imagens em total',
    formBuilderBlockedAlertPart1:
      'Você não tem permissão para editar este formulário. Se você quiser criar ou editar formulários ',
    formBuilderBlockedAlertPart2: 'atualize seu plano',
    formBuilderBlockedAlertPart3: ' agora e desbloqueie a produtividade ilimitada',
    formNameExists: 'O nome do formulário já existe',
    saveError: 'Erro ao salvar o formulário',
  },
  help: {
    multi_option: 'Selecione uma opção em uma lista de itens',
    select_metadata: 'Selecione uma opção de uma lista de dados dinâmica',
    select_option_metadata: 'Selecione uma opção para cada item na lista',
    select_option_metadata_comments: 'Respostas fixas para cada item de uma lista de perguntas',
    track_code:
      'Forneça a opção de alterar ou selecionar o código de rastreamento de cada formulário',
    static_text: 'Permite adicionar texto fixo como instrução do formulário',
    select_check_metadata: 'Selecione vários itens em uma lista de dados dinâmica',
    select_number_metadata: 'Selecione um número para cada item na lista de dados dinâmica',
    select_text_metadata: 'Escreva um texto para cada item em uma lista de dados dinâmica',
    table: 'Crie um grupo de perguntas que você pode responder mais de uma vez',
  },
  types: {
    text: 'Texto',
    date: 'Data/Hora',
    number: 'Número',
    signature: 'Assinatura',
    barcode: 'Scanner',
    photo: 'Foto',
    multi_photo: 'Foto',
    multi_option: 'Seleção Única',
    select_option_metadata: 'Checklist',
    select_option_metadata_comments: 'Checklist',
    select_check_metadata: 'Checkbox',
    select_number_metadata: 'Dados numéricos',
    select_text_metadata: 'Dados de texto',
    static_text: 'Instrução',
    select_metadata: 'Dados de opção única',
    table: 'Grupo de perguntas repetíveis',
    attachment: 'Anexar Arquivo',
    deprecated: 'Descontinuada',
  },
  links: {
    appStore: 'https://itunes.apple.com/us/app/datascope-forms/id1132697668?l=pt&ls=1&mt=8',
    playStore: 'https://app.datascope.io/apk',
    appLink: 'https://datascope.page.link/3N7P',
  },
  listManager: {
    dataListSource: 'Fonte de dados',
    name: 'Nome',
    description: 'Descrição',
    code: 'Código',
    listItems: 'Lista de itens',
    emptyList: 'Esta lista não tem itens',
    attribute1: 'Atributo 1',
    attribute2: 'Atributo 2',
    listSubtitle: 'Todas essas listas estarão disponíveis ao criar um novo formulário móvel',
    seeAll: 'Veja todas as listas',
    seeObj: 'Ver os itens da lista',
    edit: 'Editar lista',
    editObj: 'Editar item',
    delete: 'Deletar lista',
    deleteObj: 'Deletar item',
    deleteObjectWarning: 'Tem certeza de que deseja remover este item da lista?',
    deleteYes: 'Sim, eliminar',
    saveChanges: 'Salvar',
    cancel: 'Cancelar',
    itemsOf: 'Itens de:',
    addNewItem: '+ Novo item',
    page: 'Página ',
    of: ' de ',
    close: 'Cerrar',
    legacy: 'Ir para o gerenciador de lista completa',
    locations: 'Endereços',
  },
  tasksForms: {
    templates: 'Modelos de formulários',
    useTemplate: 'Usar modelo',
    newVersion: 'Mudar para a nova versão',
    oldVersion: 'Mudar para a versão antiga',
    uniq: 'O nome da pergunta deve ser exclusivo',
    title: 'Formulários',
    preview: 'visualização',
    export: 'Exportar',
    export_help: 'Você pode copiar este código fonte e colar em outro formulário',
    import: 'Importar',
    import_confirm: 'Tem certeza de que deseja importar este formulário?',
    import_success: 'Seu formulário está sendo importado, pode demorar alguns minutos',
    start_testing: 'É hora de testar seu primeiro formulário!',
    test_button: 'Testar formulário',
    start_testing_desc:
      'Parabéns, você tem um novo formulário! Agora você pode começar a testá-lo em seu aplicativo móvel.',
    import_error:
      'Falha ao importar o formulário. Verifique se é um formulário válido ou se já está importado.',
    clone: 'Clone',
    default_title: 'Meu formulário',
    default_question: 'Pergunta',
    drag_questions:
      'Ainda não existem perguntas no seu formulário. Arraste e solte as perguntas que deseja utilizar aqui',
    new: 'Novo Formulário',
    new_ai: 'Formulário com IA',
    google_forms: 'Importar Google Form',
    are_you_sure: 'Você tem certeza?',
    status: 'Status',
    active: 'Publicados',
    inactive: 'Oculto',
    publish: 'Publicar',
    unpublish: 'Omitir',
    last_info: 'Adicione HTML no final do PDF',
    current_logo: 'Forma atual Logo',
    form_logo: 'Logo formulário',
    form_name: 'Nome',
    actions: 'Configurar',
    answer_form: 'Responder',
    edit_app: 'Alterar o formulário',
    edit_pdf: 'Alterar PDF',
    edit_email: 'Mude o e-mail',
    edit_share: 'Compartilhar',
    error_form: 'Ocorreu um erro',
    success_form: 'Sucesso',
    delete: 'Deletar',
    date: 'Data hora',
    date_type: 'Tipo de data',
    date_type_date: 'Data',
    date_type_datetime: 'Data hora',
    date_type_time: 'Tempo',
    text: 'Texto',
    number: 'Número',
    multi_option: 'Seleção Única',
    signature: 'Assinatura',
    barcode: 'Scanner',
    photo: 'Foto',
    select_metadata: 'Dados de opção única',
    select_option_metadata: 'Checklist',
    track_code: 'Código de acompanhamento',
    static_text: 'Texto estático',
    select_check_metadata: 'Checkbox',
    select_number_metadata: 'Dados numéricos',
    select_text_metadata: 'Dados de texto',
    table: 'Grupo de perguntas repetíveis',
    name: 'Questão',
    description: 'Pergunta Descrição',
    label: 'Nome da Seção de Perguntas',
    choices: 'Alternativas',
    choice: 'Escolha',
    add_choice: 'Adicionar escolha',
    list_values: 'Lista de Valores',
    add_list_value: 'Adicionar valor da lista',
    add_question: 'Adicionar pergunta',
    delete_choice: 'Excluir',
    drop_question: 'Drop as perguntas repetíveis aqui',
    metadata_type: 'Fonte da Lista de Dados',
    create_metadata: 'Criar nova fonte de lista de dados',
    change_metadata: 'Alterar fonte da lista de dados',
    save_form: 'Salvar Formulário',
    version: 'versão',
    no_name: 'Sem nome',
    no_questions: 'Deve ter pelo menos uma pergunta',
    no_data: 'Nenhum formulário disponível',
    blank_table: 'Subformulário deve ter pelo menos uma pergunta',
    sub_questions: 'Sub-Perguntas',
    disable_gallery: 'Desativar Galeria',
    required: 'Obrigatório',
    back: 'Voltar',
    location: 'Lista de Endereços',
    help_title: 'Precisa de ajuda para criar seu formulário?',
    help_instruction: 'Solicite ajuda para criar formulário',
    help_description:
      '***Por favor, explique as informações que deseja coletar e anexe uma imagem do formulário que deseja converter',
    example_title_1: 'Crie seus próprios formulários digitais',
    example_description_1:
      'Com o Form Builder você pode criar formulários perfeitos. É fácil, intuitivo e poderoso.',
    success_created: 'O formulário foi criado com sucesso.',
    hide_logic: 'Mover com base na resposta',
    show_price: 'Mostrar preço',
    show_avg: 'Mostrar Média',
    show_factor: 'Mostrar Multiplicação',
    show_percent: 'Mostrar por cento',
    equal: 'Igual',
    not_equal: 'Diferente',
    if: 'SE',
    is: 'É',
    to: 'A',
    go_to: 'IR PARA',
    next_question: 'Próxima pergunta',
    finish_form: 'Termine o formulário',
    standard_pdf: 'PDF Padrão',
    customized_pdf: 'PDF Personalizado',
    open_editor: 'Abra o Editor PDF',
    own_pdf: 'Crie seu próprio layout de PDF',
    need_your_pdf: 'Precisa do seu próprio design de PDF?',
    pdf_description:
      'O construtor de PDF mais flexível para criar seus próprios documentos em um layout personalizado a partir dos dados coletados.',
    folders: 'Pastas',
    manage_folders: 'Nova pasta',
    manage_states: 'Gerenciar estados',
    view_all_folders: 'Ver todas as pastas',
    searchSend: 'Procurar',
    searchForm: 'Procurar formulário...',
    custom_pdf: {
      step1: 'Criar Modelo',
      step1_desc:
        'O primeiro passo é criar seu próprio modelo usando o editor de PDF. É possível usar todas as perguntas do formulário. A última resposta será usada como exemplo.',
      step2: 'Ativar Modelo',
      step2_desc:
        'O próximo passo é ativar o modelo quando estiver pronto. Quando você ativar isso, todos os novos PDF serão gerados com este formato.',
    },
    help: {
      multi_option: 'Selecione 1 alternativa entre varias opções inseridas manualmente',
      select_metadata: 'Selecione 1 item dentro de uma lista importada na plataforma',
      select_option_metadata: 'Respostas fixas para cada item de uma lista de perguntas',
      track_code:
        'Forneça a opção de alterar ou selecionar o código de rastreamento de cada formulário',
      static_text: 'Instruções de preenchimento (não aparece nas respostas)',
      select_check_metadata: 'Checklist de items (permite selecionar vários)',
      select_number_metadata: 'Vários campos para respostas de números em uma mesma tela',
      select_text_metadata: 'Selecione um texto para vários itens de uma lista personalizada',
      table:
        'Crie loopings para que um conjunto de perguntas possa ser respondido quantas vezes forem necessárias',
      description:
        'Texto mais longo para mostrar no aplicativo. Não aparece na exportação dos dados. (Opcional)',
      title: 'Título curto para identificar a pergunta. Aparece na exportação dos dados.',
    },
    share: {
      title: 'Gerar link Google Forms',
      link: 'Link:',
      edit_link: 'Editar Google Form',
      export: 'Exportar Google Form',
      sendgrid_template_id: 'd-8f629bbc6b7e4af7a28346e9f27bf3e7',
      inactive_public_form: 'Este formulário não está mais acessível por meio deste link.',
      success_message: 'Recebemos sua respostas corretamente',
      make_form_private: 'Remova o acesso público ao formulário',
      make_form_private_notice:
        'Este formulário agora é privado. Links públicos compartilhados anteriormente não são mais válidos.',
      make_form_public: 'Tornar este formulário público',
      make_form_public_notice:
        'Este formulário agora é público. Agora você pode compartilhar com pessoas fora de sua organização!',
      share_via_email: 'Compartilhe este formulário por meio de um email',
      share_email_tooltip:
        'Um link público, acessível a qualquer pessoa, será enviado para os e-mails que não estão associados a esta conta. Um link de uso privado será enviado para os e-mails que pertencem a usuários registrados desta conta.',
      share_public_link: 'Compartilhe este formulário por meio de um link público',
      share_public_link_tooltip:
        'Qualquer pessoa, usuários registrados e não registrados, poderá preencher este formulário se você compartilhar o link público com eles. Suas respostas serão registradas com o nome de um usuário genérico, webforms_account_',
      share: 'Compartilhar',
      share_whatsapp_message: 'Olá, convido você a preencher o seguinte formulário DataScope: ',
      copy: 'Copiar',
      trigger: 'Gatilho não instalado. Limite de 20 atingido.',
      loading: 'O formulário está carregando...',
      failed: 'Falha na exportação do formulário.',
      auth: "Adendo: 'se você tiver problemas, tente sair do Google (por exemplo, no google.com.br) e faça login apenas com a conta para a qual deseja exportar o formulário.'",
      add_all_permissions: 'Adicione este formulário a todos os usuários',
      permission_success:
        'Permissão adicionada a todos os usuários, você precisa fazer logout e login novamente no aplicativo para atualizar os formulários ',
    },
    delete_form_confirm: 'Tem certeza de que deseja eliminar este formulário?',
    delete_form_warning: 'Uma vez deletado, não pode ser recuperado',
    delete_form_success: 'Formulário deletado com sucesso',
    delete_form_failure: 'Exclusão do formulário falhou',
    clone_form_success: 'Formulário duplicado com sucesso',
    clone_form_failure: 'Falha na duplicação do formulário',
    publish_form_success: 'Formulário publicado com sucesso',
    publish_form_failure: 'Falha na publicação do formulário',
    unpublish_form_success: 'Formulário omitido com sucesso',
    unpublish_form_failure: 'Falha na omissão do formulário',
    rename: 'Renomear',
    rename_folder: 'Renomear pasta',
    folder_name: 'Nome do pasta',
    new_name: 'Novo nome',
    update: 'Atualizar',
    cancel: 'Cancelar',
    rename_success: 'Pasta renomeada com sucesso',
    rename_failure: 'Erro ao renomear a pasta',
    delete_folder_confirm: 'Tem certeza de que quer eliminar la pasta ',
    delete_warning:
      'Uma vez eliminada, estes formulários passarão a seção de Formulários (sem pasta).',
    delete_folder_success: 'Pasta eliminada com sucesso',
    delete_folder_failure: 'Erro ao eliminar a pasta',
    move_to_folder: 'Mover para a pasta',
    remove_from_folder: 'Remover da pasta',
    move: 'Mover',
    warn_empty_folder: 'Esta ação eliminará a pasta que ficar vazia.',
    move_form_success: 'Formulário movido com sucesso',
    move_forms_success: 'Formulários movidos com sucesso',
    move_form_failure: 'Erro ao tentar mover o formulário',
    move_forms_failure: 'Erro ao tentar mover os formulários',
    create: 'Criar',
    create_form_folder: 'Criar pasta de formulários',
    create_folder_success: 'Pasta criada com sucesso',
    create_folder_failure: 'Erro ao criar a pasta',
    how_to_create_folder: 'Como você quer criar sua pasta?',
    next: 'Seguinte',
    form_selection: 'Seleção de formulários',
    no_forms_available: 'Você não tem formulários disponíveis neste moment',
    forms: 'Formulários',
    form: 'formulário',
    bulk_action: {
      publish: {
        confirm: 'Tem certeza de que deseja publicar',
        info: 'Uma vez publicados, você pode desativá-los novamente.',
        success: 'Formulários publicados com sucesso',
        failure: 'Falha na publicação dos formulários',
      },
      unpublish: {
        confirm: 'Tem certeza de que deseja omitir',
        info: 'Uma vez omitidos, você pode publicá-los novamente.',
        success: 'Formulários publicados com sucesso',
        failure: 'Falha na publicação dos formulários',
      },
      delete: {
        confirm: 'Você tem certeza de que deseja eliminar',
        info: 'Uma vez eliminados, você não poderá recuperá-los novamente.',
        success: 'Formulários excluídos com sucesso',
        failure: 'Exclusão do formulários falhou',
      },
    },
    configure_signature: 'Configurar assinatura eletrônica',
    formResults: 'Resultados: ',
    formResultsOf: 'de',
    current_folder: 'Pasta atual:',
    formLimitReachedTooltip: 'Você atingiu o limite de formulários permitidos. ',
    upgradePlanTooltipLink: 'Atualize seu plano',
    upgradePlanTooltip: ' para criar formulários',
    importPermissionError: 'Você não tem permissão para importar modelos de formulários',
    projectLimitReachedError:
      'Não foi possível importar o formulário. Você atingiu o número máximo de formulários permitidos.',
  },
  mobileUsers: {
    users: 'Usuários',
    new: 'Novo usuário',
    import: 'Importar',
    import_users: 'Importar Usuários',
    how_import: 'Como importar usuários?',
    bulk_actions: 'Ações em massa',
    add_users_to_group: 'Adicionar usuários ao grupo',
    deactivate_users: 'Desativar usuários',
    add: 'Adicionar',
    add_to_group: 'Adicionar ao grupo',
    selectAGroup: 'Selecionar um grupo',
    identifier_help: 'Necessário iniciar sessão no aplicativo móvel e na web',
    duplicate_user:
      'Um usuário excluído com o mesmo e-mail já existe. Por favor, reative esse usuário em vez disso',
    old_password_required:
      'inválido. Para alterar a confirmação da senha do administrador necessária.',
    group_users: 'Grupos de usuários',
    group_new: 'Novo grupo',
    you: 'você',
    advanced: 'Configuração avançada',
    location_perm:
      'Permissões de endereço (visualizar apenas as respostas que foram preenchidas escolhendo determinados locais. Em branco = poderá visualizar todos)',
    forms_perm:
      'Permissões de formulários (Pode acessar apenas determinados formularios. Em branco = poderá acessar todos)',
    activated: 'Ativos',
    deactivated: 'Desativados',
    modifications_history: 'história',
    noGroups: 'Você não tem grupos de usuários',
    infoNotice:
      'Os usuários só poderão ver os formulários e as respostas que se aplicam a eles de acordo com seu grupo.',
    delete_group: 'Excluir grupo',
    cancel: 'Cancelar',
    delete: 'Excluir',
    rename: 'Renomear',
    rename_group: 'Renomear grupo',
    group_name: 'Nome do grupo',
    new_name: 'Novo nome',
    update: 'Atualizar',
    confirm_group_delete: 'Tem certeza que deseja deletar o grupo?',
    confirm_group_delete2:
      'Depois de excluí-lo, os usuários que não estiverem em outros grupos ficarão sem grupo e terão as permissões individuais atribuídas anteriormente.',
    update_groups_success: 'Alterações feitas com sucesso',
    update_groups_error: 'Ocorreu um erro ao salvar as alterações',
    userInfo: 'Informação do Usuário',
    userGroupsHelp: 'Este usuário pertence aos seguintes grupos:',
    manageGroupModalTitle: 'Permissões em',
    searchForm: 'Procurar formulário...',
    searchUser: 'Procurar usuário...',
    groupUpdateSuccess: 'Grupo atualizado com sucesso',
    permissions_title: 'Permissões',
    forms_title: 'Formulários',
    group_name_exists: 'Já existe um grupo com esse nome',
    edit_group_permissions: 'Permissões de formulário',
    edit_groups: 'Editar grupos',
    save: 'Salvar',
    editGroupsInfo:
      'Usuários atribuídos a um ou mais grupos receberão permissões para acessar os formulários desses grupos. Consequentemente, eles não poderão ter formulários atribuídos individualmente ao mesmo tempo.',
    modifiedGroup: 'Grupo modificado',
    modificationAuthor: 'Autor da modificação',
    modificationDate: 'Data',
    modification: 'Modificação',
    added: 'Adicionado',
    removed: 'Removido',
    group: 'Grupo',
    createdGroup: 'Grupo criado',
    deletedGroup: 'Grupo excluído',
    renamedGroup: 'Grupo renomeado',
    emptyHistory: 'Não há histórico de modificações',
    previousName: 'Nome anterior',
    removeFromGroup: 'Remover do grupo',
    remove: 'Remover',
    removeUsersConfirm: 'Você tem certeza de que deseja remover',
    removeUsersConfirm2: 'usuário(s) do grupo',
    onceUsersRemoved:
      'Uma vez removidos, eles se tornarão usuários sem grupo ou permanecerão nos outros grupos aos quais pertencem.',
    noUsersInGroup: 'Você ainda não adicionou usuários a este grupo',
    searchGroups: 'Procurar grupos...',
    pendingConfirmationTitle: 'Confirmação pendente',
    pendingConfirmationSubtitle: 'Compartilhe este link apenas com este usuário',
    copy: 'Copiar',
    linkCopied: 'Link copiado!',
    resendInvitation: 'Reenviar',
    deleteGroupPendingSignatureError: 'O grupo tem assinaturas pendentes',
    invite_user: {
      title: 'Convidar usuários',
      placeholder: 'exemplo@exemplo.com',
      help: 'Esses usuários terão permissões básicas, se você quiser alterá-las, clique em configurar permissões',
      btn: 'Convidar',
      config: 'Configurar permissões',
      successSingular: 'Convite enviado com sucesso',
      successPlural: 'Convites enviados com sucesso',
      error:
        'Falha ao convidar usuários, verifique se os e-mails são válidos e não são usados anteriormente',
      emailFormatError: 'Formato de e-mail incorreto, siga o exemplo@exemplo.com',
      emails: 'E-mails',
    },
    permissions: {
      answers: 'Pode visualizar as respostas dos formulários',
      evaluate: 'Pode alterar o status dos formulários (Plataforma Web)',
      chat: 'Pode usar o Chat (bate-papo) entre usuários da plataforma',
      tasks: 'Pode acessar todos os formulários disponíveis',
      assigned: 'Pode acessar formulários atribuidos a ele',
      users: 'Pode editar acessos ou criar novos usuários (Plataforma Web)',
      export: 'Pode exportar os dados (Plataforma Web)',
      metadata: 'Pode criar Listas Personalizadas  (Plataforma Web)',
      modify: 'Pode modificar suas próprias respostas (App)',
      edit_forms: 'Editar Formulários',
      evaluate_delete: 'Excluir/Recuperar Respostas do Formulário',
    },
    roles: {
      customized: 'Personalizado',
      administrator: 'Administrador',
      superadmin: 'Administrador Principal',
      supervisor: 'Supervisor',
      editing_operator: 'Operador de edição',
      operator: 'Operador',
    },
    table: {
      name: 'Nome',
      user: 'Usuário',
      role: 'Perfil',
      lastConnection: 'Última conexão',
      noLastConnection: 'Nenhuna conexão disponível',
      actions: 'Ações',
      configure: 'Configurar',
      edit: 'Editar',
      deactivate: 'Desabilitar',
      activate: 'Habilitar',
      no_data: 'Nenhum Usuário disponível',
      groups: 'Grupos',
      viewMore: 'Ver mais',
      noForms: 'Este grupo de usuários não possui formulários atribuídos',
      not_confirmed: 'Confirmação Pendente',
    },
    logs: {
      user: 'Usuário modificado',
      author: 'Autor da modificação',
      date: 'Data',
      modification: 'Modificação',
      activated: 'Habilitado',
      deactivated: 'Desabilitado',
    },
    deactivation: {
      cancel: 'Cancelar',
      confirmationTitle: 'Tem certeza de que deseja desativar este usuário?',
      confirmationMessage:
        'Os dados não serão perdidos. Você pode ativar o usuário novamente na seção Desativado',
      bulkConfirmationTitle: 'Tem certeza de que deseja desativar',
      bulkConfirmationMessage:
        'Os dados não serão perdidos. Você pode ativá-los novamente na seção Desativado.',
      success: 'Usuário(s) desativado(s) com sucesso',
      error: 'Ocorreu um erro ao desativar o(s) usuário(s)',
    },
    createUser: {
      information: {
        title: 'Informação',
        name: 'Nome',
        lastName: 'Sobrenome',
        email: 'Email',
        example: 'exemplo@exemplo.com',
        password: 'Senha',
        passwordHelper: 'A senha deve ter pelo menos 6 caracteres',
        phone: 'Telefone',
      },
      group: {
        title: 'Grupo',
        selectHelper: 'Selecione um grupo existente (pode selecionar vários)',
        noGroup: 'Sem grupo',
        newGroup: 'Novo grupo',
        newGroupHelper: 'Escreva o nome do grupo',
        newGroupSuccess: 'Novo grupo criado com sucesso!',
        newGroupError: 'Ocorreu um erro ao criar o novo grupo',
        configuration: 'Deve configurar autorizações a formulários para este grupo',
        formsHelp: 'Formulários aos quais este usuário terá acesso:',
      },
      forms: {
        title: 'Formulários',
        allForms: 'Formulários',
        addForm: 'Adicionar formulário',
        formsHelper: 'Você poderá ver os formulários e as respostas dos selecionados',
        formsHelperDisabled: 'Você poderá ver esses formulários e suas respostas.',
        formsGroupHelper:
          'Os formulários e lugares a que terá acesso este usuário são os que pertencem ao grupo previamente selecionado',
        seeMore: 'ver mais',
        groupsAlert:
          'Você não pode adicionar formulários porque você atribuiu ou criou um grupo anteriormente',
        back: 'Voltar',
        folders: 'Pastas',
        close: 'Fechar',
        formHasPendingSignature:
          'Este usuário tem assinaturas pendentes. Enquanto não forem respondidas ou excluídas, você não poderá modificar as permissões.',
        massSelectionBlockedByPendingSignature:
          'Seleção em massa de formulários está desativada devido a assinaturas pendentes.',
        foldersBlockedByPendingSignature:
          'Seleção de pastas está desativada devido a assinaturas pendentes. Abra a pasta para selecionar formulários.',
      },
      permissions: {
        title: 'Permissões',
        editingMyself:
          'Você não pode alterar suas próprias permissões. Peça para outra pessoa fazer isso se você quiser mudá-la.',
        basic: {
          title: 'Essencial',
          viewFormAnswers: 'Pode visualizar as respostas dos formulários',
          chat: 'Pode usar o Chat (bate-papo) entre usuários da plataforma',
          submitFormAnswers: 'Pode acessar todos os formulários disponíveis',
          viewAssignedForms: 'Pode acessar formulários atribuidos a ele',
        },
        advanced: {
          title: 'Avançado',
          changeFormStatus: 'Pode alterar o status dos formulários (Plataforma Web)',
          createMetadata: 'Pode criar Listas Personalizadas  (Plataforma Web)',
          editFormAnswers: 'Pode modificar suas próprias respostas (App)',
          editForms: 'Pode modificar seus formulários',
          evaluateDelete: 'Excluir e recuperar respostas de formulário',
        },
        admin: {
          title: 'Administrador',
          viewAndCreateUsers: 'Pode editar acessos ou criar novos usuários (Plataforma Web)',
          exportData: 'Pode exportar os dados (Plataforma Web)',
        },
        editFormsTooltip1: 'Este usuário não tem permissão para editar formulários. ',
        editFormsTooltipLink: 'Atualize seu plano',
        editFormsTooltip2: ' agora e desbloqueie a produtividade sem limites!',
      },
      locations: {
        title: 'Endereços (Opcional)',
        helper:
          'Se ativar esta opção, o usuário só poderá ver os formulários associados aos locais que selecionar',
        locations: 'Endereços',
        andOthers: ', e outros...',
      },
      signature: {
        name: 'Nome completo',
        companyName: 'Nome da empresa',
        userIdNumber: {
          default: 'Número de Identificação',
          CL: 'RUT',
          PE: 'DNI',
          MX: 'CURP',
        },
        country: 'País',
        selectCountry: 'Selecione um país',
        userEmail: 'E-mail pessoal',
        legalRepresentative: 'Representante legal',
        legalRepresentativeIdNumber: {
          default: 'Número de Identificação Fiscal do Representante Legal',
          CL: 'RUT do representante legal',
          PE: 'RUC do representante legal',
          MX: 'RFC do representante legal',
        },
        companyIdNumber: {
          default: 'Número de Identificação Fiscal da Empresa',
          CL: 'RUT da empresa',
          PE: 'RUC da empresa',
          MX: 'RFC da empresa',
        },
        address: 'Endereço',
        companyEmailError: 'Você deve usar um e-mail diferente',
        idNumberError: {
          default: 'Número de Identificação Fiscal inválido',
          CL: 'RUT inválido',
          PE: 'RUC inválido',
          MX: 'RFC inválido',
        },
        idNumberSuccess: {
          default: 'Número de Identificação Fiscal válido',
          CL: 'Seu RUT foi verificado',
          PE: 'Seu RUC foi verificado',
          MX: 'Seu RFC foi verificado',
        },
      },
      cancel: 'Cancelar',
      save: 'Guardar',
      success: 'Novo usuário criado corretamente!',
      error: 'Ocorreu um erro ao criar o novo usuário móvel',
    },
    editUser: {
      information: {
        oldPassword: 'Senha atual',
        newPassword: 'Nova senha',
        passwordChange: 'Alterar a senha',
        error: 'Ocurreu um erro ao atualizar o usuário',
        success: 'Usuário atualizado corretamente!',
      },
    },
    createGroup: {
      button: 'Criar grupo',
      title: 'Criar grupo de usuários',
      chooseHeader: 'Como quer criar o grupo?',
      name: 'Nome grupo',
      nameHelper: 'Nome do grupo',
      userSelection: 'Seleção de usuários',
      formSelection: 'Seleção de formulários',
      importExcelNote: '*Você terá que atribuir acessos aos formulários',
      warning:
        'Usuários atribuídos a um grupo terão acesso aos formulários desse grupo e não poderão ter formulários atribuídos individualmente ao mesmo tempo.',
    },
    setProfile: {
      completeProfile: 'Complete seu perfil',
      completeProfileDescription: '',
      newPassword: 'Nova senha',
      name: 'Nome',
      lastName: 'Sobrenome',
      phone: 'Telefone',
      save: 'Salvar',
      updateSuccess: 'Perfil atualizado com sucesso',
      updateError: 'Ocorreu um erro ao atualizar o perfil',
      identifier: 'Identificador',
    },
  },
  login: {
    greeting: 'Oi de novo!',
    loginHelper: 'Para continuar, insira suas credenciais',
    googleLogin: 'Faça login no Google',
    linkedinLogin: 'Entre com o Linkedin',
    email: 'Correio eletrônico',
    password: 'Dica',
    rememberMe: 'Lembre de mim',
    forgotPassword: 'Você esqueceu sua senha?',
    login: 'Iniciar sessão',
    noAccount: 'Você não tem uma conta?',
    signUp: 'Assine aqui',
    or: 'ou',
  },
  templates: {
    total_templates: 'Total templates',
    see_all: 'Ver todos',
    showing_results: 'Mostrando resultados de',
    categories: 'Categorias',
    in: 'em',
    useTemplate: 'Usar modelo',
    noResults:
      'Não pudemos encontrar o que você está buscando, mas ficaremos felizes em encontrar uma solução!',
    contactSupport: 'Conte-nos qual formulário você precisa através do Chat',
    next: 'Seguinte',
    back: 'Atrás',
    useCase: 'Pode ser usado para:',
    createdBy: 'Criado por',
    anonymousUser: 'Usuario anônimo',
    preview: 'Vista prévia',
    editableTemplate: 'Este template é editável quando for importado',
    allTemplates: 'Templates',
    noDescription: 'Sem descrição',
    bannerSlogan: 'Transforme suas operações com formulários móveis',
    templateView: 'Vista de template',
    seeLess: 'Ver menos',
    seeMore: 'Ver mais',
  },
  navbar: {
    features: 'Funcionalidades',
    solutions: 'Soluções',
    work_orders: 'Ordens de Trabalho',
    safety: 'Segurança',
    inspections: 'Inspeções',
    quality: 'Qualidade',
    resources: 'Recursos',
    blog: 'Blog',
    ds_academy: 'Academia DataScope',
    digitize: 'Carregar seu Formulário',
    roi: 'Calculadora ROI',
    pricing: 'Preços',
    sales: 'Fala com vendas',
    wapp: '',
    call: '',
    demo: '',
    sign_in: 'Acessar',
    sign_up: 'Inscreva-se Grátis',
    log_out: 'Sair',
    paths: {
      work_orders: 'ordens-trabalho',
      safety: 'seguranca',
      inspections: 'inspecoes',
      quality: 'qualidade',
      blog: 'blog',
      ds_academy: 'digitalize-suas-operacoes-usando-datascope',
      digitize: 'carregar-formulario',
      roi: 'roi',
    },
  },
  uiComponents: {
    search: 'Procurar',
    showingResultsFor: 'Mostrando resultados de pesquisa para',
    found: 'encontrados',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    selected: 'selecionado',
    close: 'Fechar',
    chooseMethodDialog: {
      fromDatascope: 'A partir de DataScope',
      importExcel: 'Importar Excel',
    },
    signature: {
      title: 'Assinatura eletrônica',
      alert:
        'Complete as informações a seguir para ativar a assinatura eletrônica. O nome e sobrenome na área de informação são necessários para criar sua assinatura.',
      accountAlert: 'Lembre-se de clicar em "Salvar assinatura".',
      saveSignature: 'Salvar assinatura',
      completeSignatureWarning:
        'Você deve preencher todos os campos obrigatórios para salvar sua assinatura',
      signatureRequiredFieldError: 'Este campo é obrigatório para criar a assinatura eletrônica',
      updateSuccess: 'Assinatura atualizada com sucesso.',
      updateError: 'Ocorreu um erro ao atualizar a assinatura.',
      invalidEmailError: 'Email inválido.',
      emailExistsError: 'O email já está em uso.',
      validatingRut: 'Validando RUT...',
      emailErrors: {
        REGEX_INVALID: 'Email inválido',
        IN_USE: 'O email já está em uso',
      },
    },
  },
  formEmailBuilder: {
    message: 'Mensagem',
    subject: 'Assunto',
    senderName: 'Nome do Remetente do Email',
    addFooter: 'Adicionar Assinatura',
    footerTitle: 'Assinatura',
    notification: 'Notificação',
    autoNotify: 'Notificar emails fixos',
    save: 'Salvar',
    cancel: 'Cancelar',
    linkName: 'Link de Download do Formulário em PDF',
    linkNamePlaceholder: 'Digite o nome que deseja que o link tenha',
    linkNameHelp:
      'O nome que você configurar será o nome no link de download que chegará ao e-mail.',
    saveSuccess: 'Configurações salvas com sucesso',
    saveError: 'Ocorreu um erro ao salvar as configurações',
    autoGeneratePdf: 'Gerar PDF automaticamente',
  },
  accountConfig: {
    information: {
      title: 'Configurações da conta',
      name: 'Nome da empresa',
      description: 'Descrição da empresa',
      email: 'Email da empresa',
      phone: 'Telefone da empresa',
      address: 'Endereço da empresa',
      timezone: 'Fuso horário',
      locale: 'Idioma',
      logo: 'Logotipo',
      uploadLogo: 'Imagem',
      logoDescription:
        'A imagem deve estar no formato .PNG ou .JPG e o tamanho deve ser 400x400 px com um peso não superior a 2 MB.',
      logoSizeError: 'O tamanho da imagem deve ser inferior a 2 MB',
      saveSuccess: 'Configuração atualizada com sucesso',
      saveError: 'Ocorreu um erro ao atualizar a configuração',
      pdfAlert: 'As informações configuradas aqui aparecerão no cabeçalho do PDF padrão.',
      confirmDeleteLogo: 'Tem certeza de que deseja excluir o logotipo?',
    },
    advancedConfig: {
      title: 'Configuração avançada',
      activateSupportUser: 'Conceder acesso ao usuário de suporte DataScope',
      securityConfig: 'Configuração de segurança',
      deactivateAccount: 'Desativar conta',
      confirmTitle: 'Você tem certeza de que deseja desativar sua conta?',
      confirmMessage:
        'Esta ação é irreversível. Também desativará todos os usuários e dados associados a esta conta.',
    },
  },
  formSignatureBuilder: {
    signatureTitle: 'Ativação de Assinatura',
    checkboxSignatureActivate: 'Ativar assinatura eletrônica',
    settingsTitle: 'Configurações gerais',
    save: 'Salvar',
    cancel: 'Cancelar',
    saveSuccess: 'Configurações salvas com sucesso',
    saveError: 'Ocorreu um erro ao salvar as configurações',
    saveErrorReload:
      'Ocorreu um erro ao salvar as configurações. Por favor, recarregue a página e tente novamente',
    accountSignatureConfigError: 'Falta configurar a assinatura eletrônica da conta',
    generalSettings: 'Configuração geral',
    mandatorySignature:
      'Assinatura obrigatória (Todos os usuários configurados devem responder à solicitação, seja assinando ou rejeitando)',
    optionalSignature: 'Assinatura opcional (Um dos signatários configurados deve assinar)',
    signersSettings: 'Configuração de signatários',
    addUsers: 'Adicionar usuários',
    authorMustSign: 'O usuário que enviar uma resposta também deve assinar',
    assignUsers: 'Atribuir usuários',
    assignOwner: 'Atribuir responsável',
    searchPlaceholder: 'Buscar usuário ou grupo',
    usersTab: 'Usuários',
    groupsTab: 'Grupos',
    addButton: 'Adicionar',
    externalUsers: 'Usuários externos',
    externalUsersDescription: 'Usuários externos poderão visualizar e assinar o formulário',
    selectResponsible: 'Selecione o responsável pela solicitação',
    addResponsible: 'Adicionar responsável',
    selectUsersToSign: 'Selecione os usuários que devem assinar o documento',
    selectResponsibleTooltip:
      'O usuário selecionado poderá acompanhar o status de todas as solicitações e editá-las, mas não poderá assinar. Para assinar, deve ser adicionado à lista de assinantes',
    simultaneousSign: 'Assinatura Simultânea',
    sequentialSign: 'Sequência de assinaturas',
    simultaneousSignInfo: 'A solicitação será enviada simultaneamente para todos os usuários',
    sequentialSignInfo: 'A solicitação será enviada na ordem que você estabelecer',
    simultaneousEmptyState: 'Arraste os usuários aqui para removê-los da sequência',
    sequentialEmptyState: 'Arraste os usuários aqui para criar uma sequência de assinaturas',
    sequentialWarningInfo:
      'Você deve adicionar todos os usuários para gerar uma solicitação - {value} usuários',
    sequentialSignSuccess: 'Sequência completa',
    automatedSignature: 'Ativar solicitação de assinatura automática',
    locationUser: 'E-mails de locais',
    locationUserTooltip: 'E-mails configurados ao selecionar a opção "Conexão com locais".',
    locationUserViewEmails: 'Ver e-mails',
    locationConnection: 'Conexão com locais',
    locationConnectionTooltip1:
      'Se o formulário incluir uma pergunta para selecionar um local, uma solicitação será enviada para os e-mails configurados lá',
    locationConnectionTooltip2:
      'Se você atribuir uma tarefa com este formulário e escolher um local, os e-mails configurados lá receberão uma solicitação quando a tarefa for concluída',
    optionalSignatureOrderDisabled:
      'Esta opção não está disponível para a configuração de assinatura opcional',
    author: 'Autor da resposta',
    authorMustSignFirst:
      'Esta posição é fixa dentro da sequência, mas você pode movê-la para Assinatura Simultânea',
  },
  pricing: {
    title: 'Descubra o plano perfeito para o seu negócio!',
    basic: {
      title: 'Básico',
      subtitle: 'Perfeito para explorar as funcionalidades do DataScope',
      features: [
        'Usuários ilimitados',
        '60 respostas de formulário/mês',
        'Dados armazenados por 30 dias',
        'Construtor de formulários básico',
        'Atribuidor de tarefas',
        'Painel padrão',
        'Resolução padrão de fotos',
        'Funcionalidade offline',
      ],
      buttonText: 'Plano atual',
    },
    standard: {
      title: 'Padrão',
      subtitle:
        'Ideal para equipes que estão dando os primeiros passos na digitalização de processos',
      features: [
        'Tudo incluído no Plano Básico +',
        'Respostas ilimitadas de formulários',
        'Dados armazenados para sempre',
        'Construtor de formulários avançado',
        'Gerador de relatórios PDF personalizado',
        'Permissões de usuário editáveis',
        'Integração de dados com outras plataformas',
        'Chat entre usuários no aplicativo',
        'Suporte por e-mail/telefone/chat',
      ],
    },
    premium: {
      title: 'Premium',
      subtitle:
        'Para empresas que precisam de uma visão abrangente e em tempo real de todas as suas operações',
      features: [
        'Tudo incluído no Plano Padrão +',
        'Dashboards avançados',
        'Suporte API',
        'Resolução configurável de fotos',
        'Executivo de conta atribuído',
        'Suporte em fluxos de trabalho',
      ],
      buttonText: 'Mudar para Premium',
    },
    enterprise: {
      title: 'Empresarial',
      subtitle: 'Para grandes empresas que buscam uma solução e suporte personalizados',
      features: [
        'Tudo incluído no Plano Premium +',
        'Implementação personalizada',
        'Suporte preferencial 24/7',
      ],
      buttonText: 'Falar com vendas',
    },
    features: 'Recursos',
    start: 'Começar',
    currentPlan: 'Plano atual',
    free: 'Grátis',
    mostPopular: 'Mais popular',
    monthlyPerUser: 'mensal por usuário',
    users: 'usuários',
    standardBold: 'Padrão',
    off: 'Desconto',
    finishesIn: 'Termina em',
    ofDiscount: 'de desconto.',
    days: 'Dias',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
    salesLink: 'https://www.vyte.in/franciscocuevas',
    activePayment: {
      demoSubscription: 'Assinatura paga',
      demoSubscriptionDescription: 'Seu plano atual inclui 30 usuários.',
      demoSubscriptionDescription1: ' usuário(s).',
      demoSubscriptionDescription2: 'Você pode convidar mais clicando no botão.',
      inviteUsers: 'Convidar usuários',
      demoBannerDescription: 'Você não possui pagamentos pendentes!',
      demoBannerDescription2:
        'Se você tiver alguma dúvida sobre sua assinatura, não hesite em entrar em contato com nossa equipe.',
      moreInfo: 'Mais informações',
      viewBillingDetails: 'Ver detalhes de faturamento',
      changeUsers: 'Alterar o número de usuários',
      modify: 'Modificar',
      monthlyValue: 'Valor mensal',
      annualValue: 'Valor anual',
      total: 'Total',
      valuesMayDiffer: '*Os valores podem variar conforme a sua moeda local.',
      modifyUsersSuccess: 'A assinatura foi atualizada com sucesso!',
      modifyUsersError:
        'Encontramos problemas ao atualizar sua assinatura. Por favor, tente novamente ou entre em contato com nossa equipe de vendas.',
      confirmModifyUsers: 'Tem certeza de que deseja alterar o número de usuários?',
      confirmModifyUsersDescription:
        'Se você alterar o número de usuários, o valor da sua assinatura será alterado de acordo e a diferença será cobrada, se aplicável.',
      numberOfUsers: 'Número de usuários',
      paymentDetails: 'Detalhes de pagamento',
    },
  },
  notifications: {
    title: 'Notificações',
    searchPlaceholder: 'Procurar por nome de formulário...',
    viewFormAnswer: 'Ver Resposta',
    viewExcel: 'Ver Excel',
    viewPdf: 'Ver PDF',
    tableHeaders: {
      date: 'Data',
      type: 'Tipo',
      form: 'Formulário',
      formCode: 'Código',
      user: 'Usuário',
      location: 'Endereço',
      actions: 'Ações',
    },
    formExport: 'Exportação do formulário',
    formAnswer: 'Resposta do formulário',
    filters: {
      title: 'Filtros',
      filterByType: 'Por tipo',
      filterByLocation: 'Por endereço',
      filterByUser: 'Por usuário',
      filterByDateRange: 'Por intervalo de datas',
      startDate: 'Início',
      endDate: 'Fim',
      invalidDate: 'Data inválida',
      invalidDateRange: 'Intervalo de datas inválido',
      to: 'até',
      eraseAll: 'Apagar tudo',
      apply: 'Aplicar',
      formAnswers: 'Respostas',
      formExports: 'Exportações',
      clear: 'Limpar',
      locationPlaceholder: 'Selecione um endereço',
      userPlaceholder: 'Selecione um usuário',
      showingResultsFor: 'Mostrando resultados de pesquisa para',
    },
    noNotifications: 'Você não tem notificações neste momento',
    noResults: 'Nenhuma notificação relacionada aos filtros aplicados foi encontrada',
    limitReached:
      'Sua conta atingiu o limite. Você deve ativar sua conta para habilitar esse recurso.',
  },
  findings: {
    title: 'Não conformidades',
    new: 'Novos',
    all: 'Todos',
    createdBy: 'Criado por',
    expires: 'Expira',
    type: 'Tipo',
    description: 'Descrição',
    searchPlaceholder: 'Procurar por nome, descrição, autor ou ID',
    createdIn: 'Criado em',
    originQuestion: 'Pergunta',
    note: 'Nota',
    viewForm: 'Ver formulário',
    createYourFirstFinding: 'Crie sua primeira não conformidade',
    emptyState:
      'Adicione uma pergunta do tipo Checklist a um formulário novo ou existente para começar a criar não conformidades.',
    goToForms: 'Ir para formulários',
    detailEmptyState: 'Selecione uma não conformidade para ver o histórico',
    findingDetail: 'Detalhes da não conformidade',
    idCopied: 'ID copiado!',
    delete: 'Excluir',
    deletedSuccessfully: 'Não conformidade excluída com sucesso',
    tabs: {
      inProgress: 'Em progresso',
      closed: 'Fechados',
    },
    options: {
      viewDetails: 'Ver detalhes',
      addImage: 'Enviar imagem',
      sendFormAnswers: {
        menu: 'Enviar resposta do formulário',
        title: 'Resposta do formulário',
        button: 'Enviar a ñao conformidade',
        cancel: 'Cancelar',
        description: 'Selecione a resposta que deseja enviar para a descoberta',
        searchPlaceholder: 'Buscar formulário',
        answeredBy: 'Respondido por',
        updatedAt: 'Atualizado em',
        noResults: 'Nenhuma resposta encontrada',
        seeAnswer: 'Ver resposta',
        noAnswers: 'Você não tem respostas no momento',
      },
      sendForms: {
        button: 'Enviar',
        menu: 'Enviar formulário',
        sync: 'Sincronizando',
      },
      share: {
        menu: 'Compartilhar link',
        title: 'Compartilhar link',
        text: 'Qualquer pessoa com o link pode acessar a não conformidade',
        activate: 'Habilitar',
        deactivate: 'Desabilitar',
        loginTitle: 'Bem-vindo ao DataScope!',
        loginDescription: 'Para continuar, por favor insira seu nome e e-mai',
        loginNamePlaceholder: 'Seu nome',
        loginEmailPlaceholder: 'E-mail',
      },
    },
    createFinding: {
      title: 'Criar não conformidade',
      requiredInformation: 'Informação necessária',
      optionalInformation: 'Informação opcional',
      cancel: 'Cancelar',
      create: 'Criar',
      name: 'Nome',
      type: 'Tipo de não conformidade',
      description: 'Descrição',
      assignees: 'Atribuído a',
      dueDate: 'Data de vencimento',
      location: 'Endereço',
      inviteParticipants: 'Convidar participantes',
      findingSuccess: 'Não conformidade criada com sucesso',
      findingError: 'Erro ao criar não conformidade',
      findingCreated: 'Não conformidade criada',
      editInFindings: "Vá para a seção 'Não conformidades' para fazer alguma alteração.",
    },
    editFinding: {
      title: 'Editar não conformidade',
      update: 'Atualizar',
      findingSuccess: 'Não conformidade atualizada com sucesso',
      findingError: 'Erro ao atualizar não conformidade',
    },
    closeFinding: {
      title: 'Fechar não conformidade',
      instruction: 'Complete as seguintes informações para fechar a não conformidade',
      closingNoteLabel: 'Em que estado fechou a não conformidade?',
      cancel: 'Cancelar',
      confirm: 'Fechar não conformidade',
    },
    status: {
      title: 'Status',
      open: 'Aberto',
      in_progress: 'Em progresso',
      paused: 'Pausado',
      closed: 'Fechado',
    },
    priority: {
      title: 'Prioridade',
      low: 'Baixa',
      medium: 'Média',
      high: 'Alta',
      critical: 'Crítica',
    },
    deleteWarningConfirm: 'Tem certeza de que deseja excluir esta não conformidade?',
    deleteWarningConfirmDescription: 'Será excluído e você não poderá recuperá-lo depois.',
    chat: {
      events: {
        finding_created: 'Não conformidade criada',
        finding_closed: 'finalizou a não conformidade',
        finding_closed_expired: 'finalizou a não conformidade após a data de vencimento estipulada',
        status: 'atualizou o estado para',
        priority: 'atualizou a prioridade para',
        description: 'atualizou a descrição para',
        expiration_date: 'atualizou a data de vencimento para',
        name: 'atualizou o nome a não conformidade para',
        location: 'atualizou o endereço para',
        added_assignees: 'adicionou',
        removed_assignees: 'eliminou',
        assignees_singular: 'como responsável',
        assignees_plural: 'como responsáveis',
        added_invitees: 'adicionou',
        removed_invitees: 'eliminou',
        invitees_singular: 'como participante',
        invitees_plural: 'como participantes',
      },
      image: {
        error: 'Error',
        sync: 'Imagem Sincronizando',
      },
    },
    export: {
      pdf: {
        title: 'Exportar PDF',
        tooltip: 'Exportar PDF',
        info: 'Você exportará todas as informações incluídas na não conformidade. Uma vez exportado, o link de download será enviado para o seu email.',
        exportSuccess: 'PDF de não conformidade exportado com sucesso',
        exportError: 'Erro ao exportar PDF de não conformidade',
        buttonTitle: 'PDF',
      },
      all: {
        title: 'Exportar tudo',
        tooltip: 'Exportar para Excel',
        info: 'Você exportará as seguintes variáveis no formato Excel: Nome, descrição, responsáveis, participantes, vencimento, prioridade e localização. Uma vez exportado, o link de download será enviado para o seu e-mail.',
        exportSuccess: 'Não conformidades exportadas com sucesso',
        exportError: 'Erro ao exportar Não conformidades',
        buttonTitle: 'Excel',
      },
      emailInputLabel: 'Email',
      cancel: 'Cancelar',
      confirm: 'Exportar',
    },
    landing: {
      headerTitle: 'Resolva não conformidades rapidamente, com rastreabilidade do início ao fim',
      infoTitle1:
        'Você poderá notificar as pessoas certas para gerar um fluxo de trabalho ideal e acompanhar a resolução da não conformidade.',
      actionButton: 'Iniciar teste gratuito',
      infoDemoTime: 'Experimente não conformidades grátis por 30 dias',
      infoDisclaimer:
        'Após o término do período de teste gratuito, você ainda poderá criar não conformidades, mas não poderá acessá-las',
      headerImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-gif-pt.gif',
      featuresTitle: 'Tudo o que você precisa',
      feature1Description:
        'Crie não conformidades de forma personalizada de acordo com sua prioridade',
      feature2Description: 'Mantenha um registro de atualizações da não conformidade',
      feature3Description: 'Realize inspeções e vincule uma não conformidade a esta',
      feature1Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature1-pt.png',
      feature2Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature2-pt.png',
      feature3Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature3-pt.png',
      footerImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-notification-pt.png',
      infoTitle2:
        'Certifique-se de não perder nenhum detalhe ao acompanhar a evolução da não conformidade',
      featuresMobileImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-features-responsive-pt.png',
    },
    demoExpired: {
      title: 'Sua versão de avaliação gratuita acabou!',
      description:
        'Para acessar seus dados, convidamos você a adquirir o módulo de Não conformidades. Você pode acessá-lo clicando no botão',
      actionButton: 'Ativar Não conformidades',
    },
    suscriptionCancelled: {
      title: 'Sua assinatura expirou',
      description:
        'Para acessar seus dados, convidamos você a pagar sua assinatura do módulo de Não conformidades',
    },
    adminAlert:
      'Você poderá ver todas as não conformidades, mas para receber atualizações, deve se atribuir como responsável ou convidado.',
    configure: 'Configurar',
    config: {
      findingTypes: 'Tipos de não conformidades',
      createType: 'Criar tipo',
      editType: 'Editar',
      deleteType: 'Excluir',
      deleteTypeDisabled: 'Você não pode ficar sem tipos de descobertas',
    },
    integrations: {
      title: 'Integração de não conformidades',
      button: 'Integrações',
      gsheetsMenuName: 'Google Sheets',
      gsheetsIntegrationLabel: 'Ir para o Google Sheets',
      infoTextPart1:
        'Para garantir uma integração adequada, privada e segura, compartilhe seu documento com permissões de',
      infoTextPart2: 'editor',
      infoTextPart3: 'apenas para',
      inputLabel: 'Novo Link',
      inputPlaceholder: 'Insira o link do documento a ser integrado',
      save: 'Salvar',
      cancel: 'Cancelar',
      copyUrl: 'URL copiado!',
      created:
        'Integração configurada com sucesso! Por favor, aguarde alguns segundos para que as mudanças entrem em vigor',
      saved: 'Alterações salvas com sucesso!',
      savingError: 'Erro ao configurar a integração',
      confirmation: 'Você deseja salvar as alterações feitas antes de sair?',
      webhooks: 'Webhooks',
      webhookUrl: 'Webhook URL',
      infoWebhooksTextPart1:
        'Automatize seus fluxos de trabalho permitindo que o DataScope acione processos em plataformas externas.',
      infoWebhooksTextPart2: 'Mantenha tudo sincronizado sem esforço',
    },
    findingTypes: {
      noType: 'Outro',
      createTitle: 'Criar Tipo de Descoberta',
      editTitle: 'Editar Tipo de Descoberta',
      name: 'Nome',
      assignees: 'Responsáveis',
      assigneesCaption: 'Se você não atribuir um responsável, poderá fazê-lo no aplicativo',
      location: 'Localização',
      locationCaption: 'Se você não atribuir um local, poderá fazê-lo no aplicativo',
      save: 'Salvar',
      update: 'Atualizar',
      cancel: 'Cancelar',
      dueDateAndPriority: 'Data de Vencimento e Prioridade',
      priorityInformationBanner:
        'Você pode selecionar uma prioridade como padrão (opcional). O criador da descoberta não poderá alterá-la, mas verá sua descrição.',
      dueDate: 'Data de Vencimento',
      dueDateCaption: 'Esta configuração começará a contar a partir da criação da descoberta',
      dueDateTypes: {
        days: 'Dias',
        hours: 'Horas',
      },
      priority: 'Prioridade',
      description: 'Descrição',
      descriptionPlaceholder: 'Escreva a descrição ou instrução da prioridade',
      additionalInformation: 'Informações Adicionais (Opcional)',
      additionalInformationBanner:
        'Atribuir formulários predefinidos para que os participantes nesta descoberta completem',
      typeCreatedSuccess: 'Tipo de descoberta criado com sucesso',
      typeCreatedError: 'Erro ao criar tipo de descoberta',
      typeDeletedSuccess: 'Tipo de descoberta excluído com sucesso',
      typeDeletedError: 'Erro ao excluir tipo de descoberta',
      typeUpdatedSuccess: 'Tipo de descoberta atualizado com sucesso',
      typeUpdatedError: 'Erro ao atualizar tipo de descoberta',
      deleteTypeAlertTitle: 'Você tem certeza de que deseja excluir este tipo de descoberta?',
      deleteTypeAlertMessage:
        'Se você excluí-lo, não poderá recuperá-lo, mas as informações relacionadas a ele permanecerão na plataforma',
    },
  },
  commonComponents: {
    formSelector: {
      title: 'Formulários',
      addForm: 'Adicionar formulário',
      searchPlaceholder: 'Buscar formulário, pasta...',
      forms: 'Formulários',
      folders: 'Pastas',
      save: 'Salvar',
      cancel: 'Cancelar',
      see: 'Ver',
      more: 'mais',
      close: 'Fechar',
      maxFormsSelected: 'Você atingiu o limite de formulários selecionados',
    },
    confirmActionAlert: {
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
    formDropdownSelector: {
      loading: 'Carregando...',
      noFormsFound: 'Nenhum formulário encontrado',
    },
    dateRangePicker: {
      last7Days: 'Últimos 7 dias',
      last14Days: 'Últimos 14 dias',
      last30Days: 'Últimos 30 dias',
      last60Days: 'Últimos 60 dias',
      last90Days: 'Últimos 90 dias',
      thisYear: 'Este ano',
    },
    locationSelector: {
      loading: 'Carregando...',
      noLocationsFound: 'Nenhum local encontrado',
    },
  },
  webHooks: {
    title: 'WebHooks',
    new: {
      title: 'Criar novo WebHook',
      token: 'Token:',
      expires_in: 'Expira em:',
      expires_at: 'Expira às:',
      form: 'Formulário',
      select_form: 'Selecionar um formulário',
      select_all_forms: 'Todos os formulários',
      url: 'Url da conexão',
      example_url: 'http://www.seuservidor.com/servico',
      send_modifications: 'Enviar modificações',
      oauth_checkbox: 'Autenticar com OAuth2',
      save: 'Salvar',
      back: 'Voltar para WebHooks',
      configure_oauth: 'Configurar OAuth 2.0',
      access_token: 'URL do Token de Acesso',
      client_id: 'ID do cliente',
      client_secret: 'Chave secreta do cliente',
      scope: 'Scope',
      save_oauth: 'Salvar Configuração OAuth',
      save_oauth_success: 'Token de OAuth criado com sucesso',
      save_oauth_error: 'Houve um erro ao criar o token de OAuth',
      token_details: 'Detalhes do Token',
      create_webhook: 'Criar WebHook',
      create_webhook_success: 'WebHook criado com sucesso',
      create_webhook_error: 'Houve um erro ao criar o WebHook',
    },
    update: {
      update_webhook: 'Atualizar WebHook',
      update_webhook_success: 'WebHook atualizado com sucesso',
      update_webhook_error: 'Houve um erro ao atualizar o WebHook',
    },
  },
  answerView: {
    alreadyRejectedSignature: 'Você recusou assinar este formulário',
    rejectConfirmationTitle: 'Tem certeza que deseja rejeitar esta solicitação?',
    rejectConfirmationDescription:
      'Uma vez rejeitado, você não poderá assinar este formulário novamente.',
    reject: 'Rejeitar',
    rejectSuccess: 'Solicitação de assinatura rejeitada!',
    rejectError: 'Não foi possível rejeitar a assinatura',
    location: 'Local',
    address: 'Endereço',
    assignee: 'Responsável',
    taskInformation: 'Informações da tarefa atribuída',
    description: 'Descrição',
    tasks: 'Tarefas',
    answeredFromMobile: 'Enviado desde o aplicativo móvel',
    answeredFromWeb: 'Enviado desde a web',
    verifySignatureAlert:
      'Para assinar o formulário, primeiro você deve verificar sua assinatura, isso só será feito uma vez.',
    configureSignatureAlert:
      'Para assinar o formulário, primeiro você deve configurar e verificar sua assinatura, isso só será feito uma vez.',
    configureSignature: 'Configurar assinatura eletrônica',
    lackOfPermissions:
      'Você não tem permissão para editar seu perfil. Solicite ao administrador que complete suas informações para que você possa continuar.',
    verifySignature: 'Verificar assinatura',
    sign: 'Assinar',
    alreadySignedAlert: 'Você já assinou este formulário',
    rejectedAlert: 'Você recusou assinar este formulário',
    goToSignatures: 'Ir para assinaturas',
    signatureConfigAlert: 'Por favor, verifique a configuração da assinatura eletrônica',
    signSuccess: 'Formulário assinado com sucesso!',
    signError: 'Ocorreu um erro ao assinar o formulário',
    signatureVerifySuccess:
      'Você verificou sua assinatura eletrônica com sucesso! Agora você poderá assinar todos os seus formulários',
    signatureVerifyError: 'Ocorreu um erro ao verificar a assinatura eletrônica',
    checkedCountInfo1: 'Elementos selecionados de uma lista de',
    checkedCountInfo2: 'elementos.',
    checkedCountInfo3: 'Ver lista',
    group: 'Grupo',
    attachedFiles: 'Estes são os arquivos anexados a esta pergunta.',
    editAnswer: 'Editar resposta',
    editAnswerDisabled: 'Você não pode editar a resposta porque tem uma solicitação de assinatura',
    metadataComments: {
      item: 'Item',
      answer: 'Resposta',
      description: 'Descrição',
      images: 'Imagens',
      issues: 'Não conformidades',
      viewIssue: 'Ver não conformidade',
    },
    expiredLink: {
      description: 'Este link expirou. Solicite um novo clicando no botão',
      button: 'Solicitar link',
      newLinkRequestedSuccess: 'O link foi enviado para o seu e-mail',
      newLinkRequestedError: 'Ocorreu um erro ao solicitar o novo link',
    },
    externalUserSign: {
      title: 'Registre sua assinatura',
      registeredDescription:
        'Sua assinatura está registrada, por favor confirme seus dados para continuar',
      unregisteredDescription:
        'Sua assinatura não está registrada, para assinar, complete os dados a seguir',
      name: 'Nome completo',
      company: 'Empresa',
      role: 'Cargo',
      confirm: 'Continuar',
      cancel: 'Cancelar',
      success: 'Formulário assinado com sucesso!',
      error: 'Ocorreu um erro ao assinar o formulário',
    },
    locationInfo: {
      title: 'Informações de locais detectados',
      description: 'A resposta foi detectada em um local da sua lista de locais',
      viewInMap: 'Ver no mapa',
      nameAndDistance: 'Nome e distância',
      distanceHelpIcon:
        'Mostra a distância entre o local selecionado na resposta e o local registrado ao responder',
      awayFromLocation: 'km do local',
    },
    signature: {
      name: 'Nome',
      idNumber: {
        CL: 'RUT',
        PE: 'DNI',
        MX: 'CURP',
      },
      email: 'Email',
      date: 'Data',
    },
  },
  electronic_signature: {
    verifySignature: 'Verificar assinatura',
    annex: 'Anexo',
    sendCode: 'Enviar código',
    errorSendingCode: 'Ocorreu um erro ao enviar o código',
    successSendingCode: 'Código enviado com sucesso',
    code_sent_to_email: 'Enviamos o código de verificação para o seu e-mail:',
    resendCodeLabel: 'Não recebeu o código? ',
    resend: 'Reenviar',
    incorrect_code: 'Código incorreto, você tem {remaining_attempts} tentativas restantes',
    maxAttemptsReached: 'Você atingiu o número máximo de tentativas, um novo código foi reenviado',
    errorVerifyingCode: 'Erro ao verificar o código',
    back: 'Voltar',
    share: 'Compartilhar',
    signatures: {
      title: 'Minhas assinaturas',
      signature_date: 'Data da assinatura',
      noPendingSignatures: 'Parabéns! Você não tem assinaturas pendentes no momento',
      noCompletedSignatures: 'Você não tem assinaturas completadas no momento',
      selected: 'Selecionados',
      sign: 'Assinar',
      viewAndDownload: 'Ver e baixar',
      massiveSignError: 'Ocorreu um erro ao assinar os formulários',
      massiveSignSuccess: 'Formulários assinados com sucesso',
      downloadError: 'Ocorreu um erro ao baixar o arquivo',
    },
    requests: {
      title: 'Solicitações enviadas',
      mandatory: 'Assinatura obrigatória',
      optional: 'Assinatura opcional',
      ongoing: 'Em andamento',
      completed: 'Finalizadas',
      searchPlaceholder: 'Buscar por usuário ou formulário...',
      form: 'Formulário',
      requestDate: 'Data da solicitação',
      requestedBy: 'Solicitado por',
      goToSign: 'Ir para assinar',
      signatureDate: 'Data da assinatura',
      signatures: 'Assinaturas',
      signed: 'Já assinaram',
      pending: 'Pendentes',
      actions: 'Ações',
      deleteRequest: 'Excluir solicitação',
      deleteSuccess: 'Solicitação excluída com sucesso',
      deleteError: 'Erro ao excluir solicitação',
      noOngoingRequests: 'Tudo em ordem! Você não tem solicitações em andamento no momento.',
      noCompletedRequests: 'Você ainda não tem solicitações finalizadas.',
      shareModal: {
        title: 'Compartilhar solicitação de assinatura finalizada',
        share: 'Compartilhar',
        infoAlert:
          'Um arquivo será enviado por e-mail. Os destinatários poderão acessar e revisar os detalhes facilmente.',
        emailInstruction: 'Insira o endereço para o qual deseja enviar o arquivo.',
        inputInstruction: 'Você pode adicionar tarefas rapidamente separando-as com vírgulas “,”.',
        cancel: 'Cancelar',
      },
      shareSuccess: 'Solicitação compartilhada com sucesso',
      shareError: 'Ocorreu um erro ao compartilhar a solicitação',
      deleteWarningTitle: 'Tem certeza de que deseja excluir esta solicitação?',
      deleteWarningMessage:
        'As assinaturas já realizadas continuarão válidas e ficarão guardadas no histórico.',
      timeline: {
        title: 'Detalhe da solicitação',
        close: 'Cerrar',
        createdRequired: '{user} enviou uma solicitação de assinatura obrigatória',
        createdOptional: '{user} enviou uma solicitação de assinatura opcional',
        createdRequiredSimultaneous:
          '{user} enviou uma solicitação de assinatura obrigatória para:',
        createdOptionalSimultaneous: '{user} enviou uma solicitação de assinatura opcional para:',
        createdRequiredSequential:
          '{user} enviou uma solicitação de assinatura obrigatória com esta ordem:',
        createdOptionalSequential:
          '{user} enviou uma solicitação de assinatura opcional com esta ordem:',
        signed: '{user} assinou o documento',
        signedOrder: '{user} assinou o documento ({number} assinantes)',
        rejected: '{user} recusou a solicitação',
        signatureRequested: 'Solicitação enviada para {user} ({number} assinantes)',
        completed: 'Todas as assinaturas requeridas foram completadas. O documento já está pronto',
        signer: 'Assinante',
      },
      tooltip: {
        signed: 'Solicitação concluída',
        rejected: 'Solicitação rejeitada',
      },
    },
    promo: {
      title: 'Assine relatórios digitalmente e garanta os processos',
      description:
        'Com o módulo de Assinaturas, você pode solicitar e assinar processos de forma simples e segura.',
      actionButton: 'Quero implementar Assinaturas!',
      moreInfoTitle: 'Garanta a rastreabilidade e reduza a margem de erro!',
      infoPoint1: 'Solicite assinaturas da sua equipe de campo e envie notificações automáticas',
      infoPoint2: 'Garante o cumprimento dos seus processos',
      infoPoint3: 'Aprovado pela Direção do Trabalho (válido somente no Chile)',
      infoPoint4: 'Acesse seus dados de forma segura a partir de qualquer dispositivo',
      moreInfoButton: 'Quero mais informações!',
      moreInfoLink:
        'https://help.mydatascope.com/pt-BR/articles/8728763-assinatura-eletronica-verificada',
    },
  },
  resume: {
    stats: {
      assignedTasks: 'Tarefas atribuídas',
      completedTasks: 'Tarefas finalizadas',
      forms: 'Formulários Respondidos',
      totalForms: 'Total de formulários',
      locations: 'Locais Visitados',
      totalLocations: 'Total de locais',
    },
    emptyState: {
      description:
        'Você ainda não respondeu a nenhum formulário! Comece a otimizar seus processos criando o primeiro',
      createFirstForm: 'Criar um formulário agora',
    },
    statuses: {
      pending: 'Pendente',
      accepted: 'Aceito',
      rejected: 'Rejeitado',
      noStatus: 'Sem estado',
    },
    table: {
      configure: 'Configurar',
      viewAnswer: 'Ver Resposta',
      form: 'Formulário',
      code: 'Código',
      user: 'Usuário',
      date: 'Data',
      status: 'Estado',
      actions: 'Ações',
      changeLog: 'Registro de mudanças',
      modifyAnswer: 'Modificar resposta',
      chat: 'Chat',
      sendToGoogleSheets: 'Enviar para Google Sheets',
      sendBackupToEmail: 'Enviar respaldo para o e-mail',
      downloadPdf: 'Baixar PDF',
      duplicateAnswer: 'Duplicar resposta',
      stateUpdateError: 'Ocorreu um erro ao atualizar o estado da resposta',
      noResults: 'Nenhuma resposta de formulário encontrada que corresponda aos filtros aplicados',
      deleteAnswer: 'Excluir',
      recoverAnswer: 'Recuperar',
    },
    bulkActions: {
      delete: 'Excluir',
      changeStatus: 'Alterar estado',
      selected: 'selecionados',
      confirmDelete: 'Tem certeza de que deseja excluir {count} respostas de formulários?',
      confirmDeleteDescription: 'Uma vez excluídos, não poderão ser recuperados',
      cancel: 'Cancelar',
      selectStatus: 'Selecionar estado',
      select: 'Selecionar',
      statuses: 'Estados',
      selectStatusPlaceholder: 'Selecione um estado',
      statusesUpdated: 'Estados atualizados com sucesso!',
      errorUpdatingStatuses: 'Erro ao atualizar estados',
      deleteSuccess: 'Respostas excluídas com sucesso!',
      deleteError: 'Erro ao excluir respostas',
      recover: 'Recuperar',
      recoverSuccess: 'Respostas recuperadas com sucesso!',
      recoverError: 'Erro ao recuperar respostas',
    },
    filters: {
      addFilter: 'Adicionar filtro',
      clearFilters: 'Limpar filtros',
      dateRange: {
        title: 'Data',
        popoverTitle: 'Filtrar por data',
      },
      location: {
        title: 'Local',
        popoverTitle: 'Filtrar por local',
      },
      form: {
        title: 'Formulário',
        popoverTitle: 'Filtrar por formulário',
        activeTab: 'Ativos',
        inactiveTab: 'Inativos',
      },
      status: {
        title: 'Estado',
        popoverTitle: 'Filtrar por estado',
      },
      user: {
        title: 'Usuário',
        popoverTitle: 'Filtrar por usuário',
      },
      formCode: {
        title: 'Código do formulário',
        popoverTitle: 'Filtrar por código do formulário',
        placeholder: 'Digite o código',
      },
      questionAndAnswer: {
        title: 'Pergunta e resposta',
        popoverTitle: 'Filtrar por pergunta e resposta',
        questionLabel: 'Pergunta',
        questionPlaceholder: 'Selecione uma pergunta',
        answerLabel: 'Resposta',
        answerDisabledPlaceholder: 'Selecione uma pergunta para ativar o filtro',
        answerPlaceholder: 'Digite a resposta',
        info: 'O filtro só se aplicará se houver uma pergunta e uma resposta',
        disabledTooltip:
          'Para usar este filtro, primeiro você deve adicionar um filtro de formulário',
      },
      finding: {
        title: 'Não conformidade',
        popoverTitle: 'Filtrar por não conformidade',
        includesFinding: 'Inclui não conformidade',
        withoutFindings: 'Sem não conformidades',
      },
      signature: {
        title: 'Assinatura',
        popoverTitle: 'Filtrar por assinatura',
      },
      answeredFrom: {
        title: 'Meio de envio',
        popoverTitle: 'Filtrar por meio de envio',
        mobile: 'Móvel',
        web: 'Web',
      },
    },
    signature: {
      mandatoryIncomplete: 'Assinatura obrigatória - Incompleta',
      mandatoryComplete: 'Assinatura obrigatória - Completa',
      optionalIncomplete: 'Assinatura opcional - Incompleta',
      optionalComplete: 'Assinatura opcional - Completa',
    },
    recentTitle: 'Formulários Recentes',
    deletedTitle: 'Formulários Eliminados',
    searchBarPlaceholder: 'Buscar por nome do formulário',
    dateRange: 'Mostrando resultados para',
    changeLog: {
      title: 'Registro de mudanças',
      details: 'Ver detalhes',
      userChangedAnswer: '{user} modificou a resposta',
      userChangedState: '{user} alterou o estado da resposta para {state}',
      userRecoveredAnswer: '{user} recuperou a resposta',
      userDeletedAnswer: '{user} eliminou a resposta',
      userDuplicatedAnswer: '{user} duplicou a resposta',
      userStartedAnswer: '{user} começou a responder',
      userResetState: '{user} reestabeleceu o estado da resposta',
    },
    close: 'Fechar',
    export: {
      title: 'Exportar',
      taskForm: 'Formulário',
      dateRange: 'Intervalo de data',
      downloadFormat: 'Formato de download',
      email: 'Email',
      downloadFormats: {
        answers: 'Excel agrupado por resposta',
        forms: 'Excel agrupado por formulário',
        subforms: 'Excel agrupado por perguntas repetíveis',
        pdfs: 'Documentos PDF',
      },
      exportCellLimit:
        'Limite de exportação por arquivo: 30.000 células. Selecione intervalos de data menores se você ultrapassar.',
      exportDateRangeLimit:
        'Limite de exportação: 180 dias (6 meses). Selecione um intervalo de data menor se você ultrapassar.',
      cancel: 'Cancelar',
      success: 'Exportação enviada com sucesso',
      error: 'Ocorreu um erro ao exportar as respostas',
    },
    sendToGoogleSheets: {
      success: 'Resposta enviada para Google Sheets com sucesso',
      error: 'Ocorreu um erro ao enviar a resposta para Google Sheets',
    },
    duplicateAnswer: {
      success: 'Resposta duplicada com sucesso',
      error: 'Erro ao duplicar resposta',
    },
    sendBackupModal: {
      title: 'Enviar respaldo para o e-mail',
      description:
        'Escreva o e-mail onde você deseja receber o arquivo de respaldo deste formulário',
      send: 'Enviar',
      cancel: 'Cancelar',
    },
    deleteAnswer: {
      title: 'Tem certeza de que deseja excluir esta resposta?',
      description:
        'Uma vez excluída, não poderá ser recuperada. Você poderá recuperar a resposta filtrando por formulários excluídos e restaurando-a a partir de sua configuração.',
      confirm: 'Excluir',
      cancel: 'Cancelar',
      success: 'Resposta excluída com sucesso',
      error: 'Erro ao excluir resposta',
    },
    recoverAnswer: {
      success: 'Resposta recuperada com sucesso',
      error: 'Erro ao recuperar resposta',
    },
  },
};
