import { Backdrop, Box, SxProps, Theme } from '@mui/material';

import { DSSpinner } from './logoSpinner';

const customStyles: Record<string, SxProps<Theme>> = {
  backdrop: {
    zIndex: 1000,
  },
  loadingModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '10px',
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
};

interface LoadingScreenProps {
  description?: JSX.Element;
  fullScreen?: boolean;
}

export function LoadingScreen({ description, fullScreen = true }: LoadingScreenProps) {
  const loadingContent = (
    <Box sx={customStyles.loadingModal}>
      <DSSpinner />
      {description}
    </Box>
  );

  if (fullScreen) {
    return (
      <Backdrop
        open
        sx={customStyles.backdrop}
      >
        {loadingContent}
      </Backdrop>
    );
  }

  return <Box sx={customStyles.container}>{loadingContent}</Box>;
}
