import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { format } from 'date-fns';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { taskAssignsApi } from '../../../axios';
import { useLanguage, useRedirect } from '../../../hooks';
import { CreateTaskAssignBody, NameAndIdElement, NameAndIdElementTuple } from '../../../types';
import { formatNotifications } from '../utils/editTaskHelpers';
import { createNotifString } from './addnotification';
import {
  initialState,
  TaskAssigned,
  TaskAssignerActions,
  TaskAssignerReducer,
} from './TaskAssignerReducer';

export const TaskAssignerContext = createContext<{
  task: TaskAssigned;
  dispatchTask: React.Dispatch<TaskAssignerActions>;
  formsList: NameAndIdElement[];
  saveTask: () => void;
  goBack: () => void;
  isSubmitting: boolean;
  confirmationModalOpen: boolean;
  setConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editTask: () => void;
  editMode: boolean;
  disableSaveButton?: boolean;
}>(null);

export const TaskAssignerProvider = ({ children }: { children: ReactNode }) => {
  const { taskId } = useParams();
  const [formsList, setFormsList] = useState<NameAndIdElement[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { handleRedirect } = useRedirect();
  const lang = useLanguage();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [params] = useSearchParams();

  const [task, dispatchTask] = useReducer(TaskAssignerReducer, initialState);

  const refreshMetadataList = async () => {
    const { forms } = await taskAssignsApi.getLightFilterMetadata();
    const mapEntries = (entry: NameAndIdElementTuple) => ({
      label: entry[0],
      id: entry[1],
    });

    setFormsList(forms.map((entry) => mapEntries(entry)));
  };

  function parseDateKeepingTimezone(dateString: string) {
    const dateWithoutTimezone = moment
      .utc(dateString.substring(0, 23))
      .format('M/DD/yy, hh:mm:ss a')
      .toUpperCase();
    return new Date(dateWithoutTimezone);
  }

  const refreshEditInfo = async () => {
    try {
      const data = await taskAssignsApi.getEditInfo(parseInt(taskId));
      dispatchTask({
        type: 'task_data_loaded',
        payload: {
          users: { value: [data.task.mobile_user_id], showError: false, missing: false },
          forms: { value: [data.task.form_id], showError: false, missing: false },
          location: { value: data.task.location_id, showError: false, missing: false },
          description: { value: data.task.description, showError: false, missing: false },
          gap: { value: data.task.gap, showError: false, missing: false },
          subTasks: {
            value: data.subtasks.map((subtask: any) => subtask.name),
            showError: false,
            missing: false,
          },
          dates: {
            value: [new Date(data.task.start_time)],
            showError: false,
            missing: false,
          },
          hour: {
            value: parseDateKeepingTimezone(data.task.start_time),
            showError: false,
            missing: false,
          },
          notifications: {
            value: data.task.notifications ? formatNotifications(data.task.notifications) : [],
            showError: false,
            missing: false,
          },
          required: {
            value: data.task.mandatory === 'for_everyone' ? 2 : 0,
            showError: false,
            missing: false,
          },
          canSubmitLate: {
            value: data.task.late_response_allowed,
            showError: false,
            missing: false,
          },
        },
      });
    } catch (error) {
      handleRedirect('task_assigns');
      enqueueSnackbar(lang.task_assigner.task_does_not_exist, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    refreshMetadataList();
    if (taskId) refreshEditInfo();
  }, []);

  const goBack = () => {
    const filters = params.get('filters');
    const page = params.get('page');
    if (filters || page) {
      const redirectParams = new URLSearchParams({ filters, page }).toString();
      return handleRedirect(`task_assigns?${redirectParams}`);
    }
    return handleRedirect('task_assigns');
  };

  const parseTask = (task: TaskAssigned): CreateTaskAssignBody => {
    const formattedTask: CreateTaskAssignBody['task_assign'] = {
      location_id: task.location.value,
      start_time: task.dates.value.map((date) => format(date, 'dd/MM/yyyy')).join(','),
      description: task.description.value,
      gap: task.gap.value.toString(),
      form_id: task.forms.value[0],
      mobile_user_id: task.users.value[0],
      late_response_allowed: task.canSubmitLate.value,
      mandatory: task.required.value,
      notifications:
        task.notifications.value.length > 0 ? createNotifString(task.notifications.value) : null,
    };
    return {
      task_assign: formattedTask,
      mobile_users_ids: task.users.value,
      task_forms_ids: task.forms.value,
      groups_ids: [],
      'todo-value': task.subTasks.value.join(','),
      time: {
        date: task.hour.value.getHours() + ':' + task.hour.value.getMinutes(),
      },
    };
  };

  const saveTask = async () => {
    setIsSubmitting(true);
    try {
      const parsedTask = parseTask(task);
      await taskAssignsApi.createTaskAssign(parsedTask);
      setConfirmationModalOpen(false);
      enqueueSnackbar(lang.task_assigner.success_message, {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        autoHideDuration: 6000,
      });
      setTimeout(() => {
        goBack();
      }, 300);
    } catch (showError) {
      setIsSubmitting(false);
      enqueueSnackbar(lang.task_assigner.our_problem, {
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      });
    }
  };

  const editTask = async () => {
    setIsSubmitting(true);
    try {
      const parsedTask = parseTask(task);
      await taskAssignsApi.updateTaskAssign(parseInt(taskId), parsedTask);
      setConfirmationModalOpen(false);
      enqueueSnackbar(lang.task_assigner.update_success_message, {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        autoHideDuration: 4000,
      });
      setTimeout(() => {
        goBack();
      }, 300);
    } catch (showError) {
      setIsSubmitting(false);
      enqueueSnackbar(lang.task_assigner.our_problem, {
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      });
    }
  };

  const disableSaveButton = Object.values(task).some((value) => value.missing);

  return (
    <TaskAssignerContext.Provider
      value={{
        task,
        dispatchTask,
        formsList,
        saveTask,
        goBack,
        isSubmitting,
        confirmationModalOpen,
        setConfirmationModalOpen,
        editTask,
        editMode: Boolean(taskId),
        disableSaveButton,
      }}
    >
      {children}
    </TaskAssignerContext.Provider>
  );
};
